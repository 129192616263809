import React, { useEffect, useState } from "react";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Col, Row, Form, Button, Modal, Card } from "react-bootstrap";

import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { STUDENTS } from "../../apollo/students";
import { UPDATE_STUDENT } from "../../apollo/registrationstudent";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { formatDateDash, onConvertGender } from "../../common/super";
import Loading from "../../common/Loading"

export default function CardStudent() {
  let NOW = new Date().getFullYear();
  let assessmentYearNow = NOW + " - " + (NOW + 1);
  const { history, match } = useReactRouter();
  const codeId = match?.params?.student;
  const [data, setData] = useState({});
  const [statusSteps, setStatusSteps] = useState(false);
  const [studentStatus, setStudentStatus] = useState("REQUEST")
  // const [statusRQR, setStatusRQR] = useState();
  // const [statusRR, setStatusRR] = useState();
  const [statusRA, setStatusRA] = useState();
  const [clickCheckbox, setClickCheckbox] = useState();
  const [acceptTerms, setAcceptTerms] = useState();
  const [dataStudent, { data: student, loading: loadStudentData }] = useLazyQuery(STUDENTS, {
    variables: {
      where: { code: codeId, assessmentYear: assessmentYearNow },
      orderBy: "createdAt_DESC",
    },
  });

  const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT);

  useEffect(async () => {
    await dataStudent();
    setData(student?.students?.data[0]);
    setClickCheckbox(student?.students?.data[0]?.acceptTerms);
    setAcceptTerms(student?.students?.data[0]?.acceptTerms);
  }, [student, data]);

  useEffect(() => {
    if (data?.statusStep !== "REJECTED") {
      setStatusSteps(true);
    }else{
      setStatusSteps(false);
    }
    setStudentStatus(data?.statusStep)
    // setStudentStatus("ADMIN_APPROVED")
    // setStudentStatus("APPROVED")
    setStatusRA(
      data?.statusStep == "REQUEST"
        ? true
        : data?.statusStep == "APPROVED"
        ? true
        : false
    );
  }, [data]);

  useEffect(async () => {
    if (clickCheckbox == true) {
      try {
        updateStudent({
          variables: {
            where: { id: data?.id },
            data: { acceptTerms: true },
          },
        });
      } catch (err) {
        console.log("err", err);
      }
    }
    if (clickCheckbox == false) {
      try {
        await updateStudent({
          variables: {
            where: { id: data?.id },
            data: { acceptTerms: false },
          },
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  }, [clickCheckbox, data]);
  // const data = dataStudent?.data?.students?.data[0]

  const _clickCard = () => {
    history.push("/registrationstudentinfo", data);
  };
  const _clickPresentATemporaryStudentCard = () => {
    history.push("/presentatemporarystudentcard");
  };
  const _clickTuition_payment_channels = () => {
    history.push(`/tuitionpaymentchannels`, data);
  };
  const _clickQRcodePresentATemporaryStudentCard = () => {
    history.push("/onepay");
  };
  const _clickShowDetail = () => {
    history.push("/payment/successpayment", data);
    // history.push('/tuitionpaymentchannels', data)
  };

  const _clickCheckbox = () => {
    setClickCheckbox(false);
    setClickCheckbox(!clickCheckbox);
    setAcceptTerms(!clickCheckbox);
  };

  if(loadStudentData) return (<Loading />)
  return (
    <div className="container container-sm container-md container-lg container-xl container-fluid">
      <div className="card card-margin-top">
        <div className="card-body">
          <h1 className="card-titlen text-resize5">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</h1>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10 col-xl-10">
              <div className="card-card-dashboard">
                <div className="user-radius">
                  <FontAwesomeIcon
                    className="user-radius size-responsive"
                    icon={faUserCircle}
                    size="7x"
                    color={"#888888"}
                  />
                </div>
              </div>
              <div className="card-dashboard-body" style={{border: "1px solid #ccc" }}>
                <div className="col-xs-12 col-md-6 offset-md-3 text-card-student">
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-5">
                      <b>ຊື່​ນັກ​ສຶກ​ສາ:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-5">
                      <span>
                        {onConvertGender(data?.gender)} {data?.firstNameL}{" "}
                        {data?.lastNameL}
                      </span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>ລະຫັດສອບເສັງ:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>{data?.examNumber}</span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>ວັນ​ເດືອນ​ປີ​ເກີດ:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>
                        {formatDateDash(data?.birthday)}
                      </span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>ແກ້​ໄຂ​ຂໍ​້​ມູນ​ລ່າ​ສຸດ:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>
                        {formatDateDash(data?.createdAt) ?? "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-md-6 offset-md-3 text-card-student text-resize3 mt-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: "#057CAE",
                    textDecoration: "underline",
                  }}
                  onClick={() => _clickCard()}
                >
                  {statusSteps === false ?
                  <span style={{ color: "#FF0000" }}>
                    ຖືກປະຕິເສດ{" "}
                    <span
                      onClick={() =>
                        history.push(`/registrationStudent`, {code: data?.code, studentType: data?.studentType})
                      }
                      style={{
                        color: "#057CAE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      (ເຂົ້າແກ້ໄຂ)
                    </span>
                  </span>
                  : <b>ສະ​ແດງ​ຂໍ​້​ມູນ​ນັກ​ສຶກ​ສາ</b>
                  }
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="w-100">
              <h3 className="text-resize5">ຂັ້ນຕອນການລົງທະບຽນ:</h3>
              <Stepper orientation="vertical" >
                <Step active={statusSteps}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="text-bold colorblack text-resize3 p-0 m-0">
                          ຂັ້ນ​ຕອນ​ທີ່ 1 | ລົງ​ທະ​ບຽນປ້ອນ​ຟອມ​ຂໍ້​ມູນ
                        </h4>
                      </div>
                      <div className="col-md-1" style={{height: 10}} />
                      <div className="col-md-3">
                        <div className="text-resize3">
                          ສະຖານະ:
                          <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                            {" "}
                            ປ້ອນຂໍ້ມູນສຳເລັດ
                          </span>
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ຜູ້ສະໝັກລ໋ອກອິນເຂົ້າລະບົບລົງທະບຽນສອບເສັງ ຜ່ານເວບໄຊ: https://preregistration.slms-cu.edu.la, ປ້ອນແບບຟອມລົງທະບຽນອອນລາຍ.
                        <br /><br />
                        ຈາກນັ້ນ, ໄປແຈ້ງຢືນຢັນນຳພະນັກງານລົງທະບຽນທີ່ສູນຂໍ້ມູນຂ່າວສານ ມະຫາວິທະຍາໄລຈຳປາສັກ ເພື່ອຮັບເອົາບັດນັກສຶກສາຊົ່ວຄາວໂດຍການແຈ້ງລະຫັດເຂົ້າຫ້ອງສອບເສັງ
                        ພ້ອມດ້ວຍເອກະສານລົງທະບຽນ ເຊັ່ນ: ຮູບ 3x4 (3ໃບ), ໃບປະກາດສະນີຍະບັດ ມ.7 ແລະ ໃບຢັ້ງຢືນຄະແນນຂອງຕົນ.
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus == "APPROVED" ? true : false}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="text-bold colorblack text-resize3 p-0 m-0">
                          ຂັ້ນ​ຕອນ​ທີ່ 2 | ຈ່າຍເງິນຄ່າລົງທະບຽນເຂົ້າຮຽນ
                        </h4>
                      </div>
                      <div className="col-md-1" style={{height: 10}} />
                      <div className="col-md-3">
                        <div className="text-resize3">
                          ສະຖານະ: 
                          { studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              ຢືນຢັນແລ້ວ
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ເມື່ອມາຮອດຈຸດລົງທະບຽນ: ໃຫ້ນັກສຶກສາສະແດງເລກລະຫັດສອບເສັງຂອງຕົນ ແກ່ພະນັກງານລົງທະບຽນ ເພື່ອຊໍາລະເງິນຄ່າລົງທະບຽນເຂົ້າຮຽນ.
                        <br />
                        ປະກອບມີ: ຄ່າລົງທະບຽນເຂົ້າຮຽນ 210,000 ກີບ, ຄ່າໜ່ວຍກິດ (ຂຶ້ນກັບສາຂາທີ່ເລືອກ), ຄ່າເຄື່ອງແບບ, ແລະ ຄ່າບູລະນະລະບົບ ລຮສທ (ລະດັບປະລິນຍາຕີ: 340,000 ກີບ,
                        ປະລິນຍາຕີຕໍ່ເນື່ອງ: 170,000 ກີບ, ປະລິນຍາໂທ: 197,000 ກີບ).
                        <br /><br />
                        <b>ໝາຍເຫດ: </b>ສໍາລັບນັກສຶກສາໃນແຜນ ແມ່ນບໍ່ໄດ້ເສຍຄ່າໜ່ວຍກິດ.
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus == "APPROVED" ? true : false}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="text-bold colorblack text-resize3 p-0 m-0">
                          ຂັ້ນ​ຕອນ​ທີ່ 3 | ແຈ້ງພະນັກງານລົງທະບຽນ
                        </h4>
                      </div>
                      <div className="col-md-1" style={{height: 10}} />
                      <div className="col-md-3">
                        <div className="text-resize3">
                          ສະຖານະ: 
                          { studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              ຢືນຢັນແລ້ວ
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ຜູ້ສະໝັກແຈ້ງໃບຊຳລະຄ່າລົງທະບຽນນຳພະນັກງານລົງທະບຽນ ເພື່ອຢືນຢັນການຊຳລະ. <br/>
                        ຈາກນັ້ນ, ສະແດງໃບບິນຊຳລະເງິນ ແລະ ເອກະສານໃນຊອງ ແກ່ພະນັກງານລົງທະບຽນດັ່ງນີ້:  <br /><br />
                        •	ສຳລັບນັກສຶກສານອກແຜນ: <br />
                        ບັດເຂົ້າຫ້ອງສອບເສັງ ມ.ຈ, ຊີວະປະຫວັດຈົບ ມ.7 (ກາແດງ), ສຳເນົາໃບປະກາດ ມ.7 ແລະ ໃບຢັ້ງຢືນຄະແນນ (ຖືຕົ້ນສະບົບມາຢັ້ງຢືນ), 
                        ໃບຢັ້ງຢືນທີ່ຢູ່ (ກາແດງ) ຫຼື ສຳເນົາສຳມະໂນຄົວ, ໃບກວດສຸຂະພາບ (ກາແດງ), ຮູບ 3x4 ຖ່າຍບໍ່ເກີນ 3 ເດືອນ (3 ໃບ).<br /><br />
                        •	ສຳລັບນັກສຶກສາໃນແຜນ:<br />
                        ບັດເຂົ້າຫ້ອງສອບເສັງ ມ.ຈ, ຊີວະປະຫວັດຈົບ ມ.7 (ກາແດງ), ສຳເນົາໃບປະກາດ ມ.7 ແລະ ໃບຢັ້ງຢືນຄະແນນ (ຖືຕົ້ນສະບົບມາຢັ້ງຢືນ), 
                        ໃບຢັ້ງຢືນທີ່ຢູ່ (ກາແດງ) ຫຼື ສຳເນົາສຳມະໂນຄົວ, ໃບກວດສຸຂະພາບ (ກາແດງ), ຮູບ 3x4 ຖ່າຍບໍ່ເກີນ 3 ເດືອນ (3 ໃບ) ແລະ ໃບນຳສົ່ງ
                        ຈາກພະແນນສຶກສາແຂວງ (ກາແດງ) ສຳລັບນັກສຶກສາ ທີ່ໄດ້ທຶນໃນແຜນຂອງແຂວງ. <br /><br />
                        ຈາກນັ້ນ, ລໍຖ້າຮັບເອົາບັດນັກສຶກສາຊົ່ວຄາວ ແລະ UserId, Password ລະບົບ SLMS ຂອງນັກສຶກສານໍາພະນັກງານລົງທະບຽນໄດ້ເລີຍ.
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus == "APPROVED"}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="text-bold colorblack text-resize3 p-0 m-0">
                          ຂັ້ນ​ຕອນ​ທີ່ 4 | ສໍາເລັດການລົງທະບຽນເຂົ້າຮຽນ
                        </h4>
                      </div>
                      <div className="col-md-1" style={{height: 10}} />
                      <div className="col-md-3">
                        <div className="text-resize3">
                          ສະຖານະ:
                          { studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              ລົງທະບຽນສຳເລັດ
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={studentStatus == "APPROVED"}>
                    <Typography>
                      <div className="text-resize3">
                        ທ່ານໄດ້ສຳເລັດການລົງທະບຽນເຂົ້າຮຽນທີ່ ມະຫາວິທະຍາໄລຈຳປາສັກ.
                      </div>
                      <div className="text-resize3 mt-3">
                        Link ເຂົ້າລະບົບ Student: <strong><u>https://student.slms-cu.edu.la/</u></strong>
                      </div>
                      <div className="text-resize3 mt-2">
                        ລະຫັດນັກສຶກສາ: "<strong>{data?.studentNumber}</strong>"
                      </div>
                      <div className="text-resize3">
                        ລະຫັດຜ່ານ: "<strong>{data?.studentNumber}</strong>"
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
