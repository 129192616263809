import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Button, Modal } from "react-bootstrap";
import RegistrationConfirmEn from "./RegistrationConfirmEn";
import useReactRouter from "use-react-router";
import CustomButton from "../../common/CustomButton";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { v4 as uuidv4 } from 'uuid';
import {
  USER_KEY,
  PRIMARY_COLOR
} from "../../consts/index";
import { formatDateYYMMDD } from "../../common/super";
import Loading from "../../common/Loading"
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import {
  SLMS_FACULTIES,
  SLMS_DEPARTMENTS,
  CREATE_STUDENT,
  UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { PRE_SIGNED_URL } from "../../apollo/registrationstudent";
import { COUNTRIES_LIST } from "../../consts/Countries";
import { STUDENTS, TRIBES } from "../../apollo/students";

import _, { set } from "lodash";
import { createGlobalStyle } from "styled-components";

export default function RegistrationStudentEn() {
  const { history, match } = useReactRouter();
  const [getCode, setGetCode] = useState();
  // const studentType = match.params.studenttype;
  const {studentType, code} = history.location.state
  const [student, setStudent] = useState({});
  /* <---------- useState faculty ----------> */
  const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1);
  const [facultyId, setFacultyId] = useState();

  /* <-------- useState Department ---------> */
  const [departments, setDepartments] = useState();
  const [departmentName, setDepartmentName] = useState();

  /* <--------  file upload ---------> */
  const [fileUpload, setFileUpload] = useState(null);
  const [passportUrl, setPassportUrl] = useState(null);
  const [passport, setPassport] = useState(null);
  const [qualificationUrl, setQualificationUrl] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [otherUrl, setOtherUrl] = useState(null);
  const [other, setOther] = useState(null);

  /* <--------  general State ---------> */
  const [graduatedYear, setGraduatedYear] = useState([]);
  const [formParam, setFormParam] = useState({});
  const [show, setShow] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const [click, setClick] = useState("");
  const [type, setType] = useState("");
  const [errorInput, setErrorInput] = useState({});
  const [tribeId, setTribeId] = useState();
  const [renderCode, setRenderCode] = useState();
  const [scholarship, setScholarship] = useState();
  const [dataLocalStorages, setDataLocalStorages] = useState({});
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
  const [stayTogether, setStayTogether] = useState(false);

  /* <--------- apollo -------> */
  const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL);
  const [loadFaculties, { data: facultyApollo }] = useLazyQuery(SLMS_FACULTIES);
  const [createStudent] = useMutation(CREATE_STUDENT);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [loadDataDepartmentPrice, { data: dataDepartmentPrice }] = useLazyQuery(
    SLMS_DEPARTMENTS
  );

  const _onLogout = () => {
    if (student?.statusStep !== "REJECTED") {
      history.push(`/`);
      localStorage.removeItem(USER_KEY);
    } else {
      history.goBack();
    }
  };
  const { data, loading } = useQuery(STUDENTS, {
    variables: {
      where: { code: renderCode },
      orderBy: "createdAt_DESC",
    },
  });
  useEffect(() => {
    setRenderCode(code);
    setGetCode(getUserDataFromLCStorage()?.code);
    setDataLocalStorages(getUserDataFromLCStorage());
  }, []);
  const departmentPricesData = dataDepartmentPrice?.slmsDepartments;
  const { data: dataTribe } = useQuery(TRIBES);

  useEffect(() => {
    loadFaculties();
    setStudent(data?.students?.data[0]);
    const _renderCode = _.split(getCode, "/");
    setRenderCode(_renderCode[0]);
  }, [data, student]);

  useEffect(() => {
    setPassport(student?.passportUrl?.url);
    setQualification(student?.qualificationUrl?.url);
    // setScorecard(student?.scorecardUrl?.url)
    setPassportUrl(student?.passportUrl?.url);
    setQualificationUrl(student?.qualificationUrl?.url);
    // setScorecardUrl(student?.scorecardUrl?.url)
    setOtherUrl(student?.otherUrl?.url);
    setOther(student?.otherUrl?.url);

    setStayTogether(student?.stayTogether)
    _setIsSave();
  }, [student]);

  useEffect(async () => {
    if (student?.faculty?.id) {
      _selectFacultyEffect(
        student?.faculty?.id || dataLocalStorages?.faculty?.id
      );
    }
    if (dataLocalStorages?.faculty?.id) {
      await loadDataDepartmentPrice({
        variables: {
          where: {
            faculty: dataLocalStorages?.faculty?.id,
          },
        },
      });
    }
  }, [student?.faculty?.id, student?.department?.id, dataLocalStorages]);

  // select faculty
  const _selectFacalty = (e) => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: e.target.value,
    });

    if (e?.target?.value) {
      loadDataDepartmentPrice({
        variables: {
          where: {
            faculty: e.target.value,
          },
        },
      });
    }
    setFacultyId(e.target.value);
    setDepartments(facultyApollo?.slmsFaculties[facultyIndex]?.departments);
    setSelectFacultyIndex(facultyIndex);
  };

  const _selectFacultyEffect = async (id) => {
    loadDataDepartmentPrice({
      variables: {
        where: {
          faculty: id,
        },
      },
    });
  };

  // select department
  const _selectDepartment = (e) => {
    const departmentIndex = _.findIndex(departmentPricesData, {
      id: e.target.value,
    });
    setDepartmentName(departmentPricesData[departmentIndex]?.name);
  };

  // useEffect province from database
  useEffect(() => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: student?.faculty?.id || dataLocalStorages?.faculty?.id,
    });
    setSelectFacultyIndex(facultyIndex);
    setDepartmentName(dataLocalStorages?.department?.name);
  }, [student, data, student, facultyApollo]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 1; i >= 1995; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setGraduatedYear(year);
  }, []);

  useEffect(() => {
    _setIsSave();
  // }, [passportUrl, otherUrl, qualificationUrl]);
  }, [passportUrl, qualificationUrl]);

  useEffect(() => {
    if (dataPreSign?.preSignedUrl) {
      fileGetResign(dataPreSign?.preSignedUrl);
    } else {
      setIsLoading(false)
    }
  }, [dataPreSign]);

  const fileGetResign = async (data) => {
    try {
      if (click == "1") {
        setPassportUrl(data?.url.split("?")[0]);
      }
      if (click == "2") {
        setQualificationUrl(data?.url.split("?")[0]);
      }
      if (click == "3") {
        setOtherUrl(data?.url.split("?")[0]);
      }
      await axios({
        method: "put",
        url: data.url,
        data: fileUpload,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  // ເລືອກໄຟລຈາກ ຄອມ
  const _handleChangeFile = async (acceptedFiles) => {
    try {
      // ໄປຂໍ url ຈາກ s3
      setIsLoading(true)
      let data = uuidv4() + '.' + acceptedFiles?.target?.files[0].type.split('/')[1];
      switch (click) {
        case "1":
          setPassport(data);
          break;
        case "2":
          setQualification(data);
          break;
        case "3":
          setOther(data);
      }
      setFileUpload(acceptedFiles?.target?.files[0]);
      loadPreSign({
        variables: {
          name: data,
        },
      });
    } catch (error) {
      setIsLoading(false)
    }
  };
  var arr = [];
  const _setIsSave = () => {
    // if (passportUrl && otherUrl && qualificationUrl) {
    if (passportUrl && qualificationUrl) {
      setIsSave(false);
    } else {
      setIsSave(true);
    }
  };

  const _selectTribe = (tribe) => {
    setTribeId(dataTribe?.tribes[tribe.target.value]?.id);
  };

  const selectScholarship = (e) => {
    setScholarship(e.target.value);
  };

  const _clickStayTogether = (e) => {
    // setStayTogether(true);
    setStayTogether(!stayTogether);
  };
  const handleSubmit2 = async (event, values) => {
    event.preventDefault();
    var errors = {};
    if (studentType == "IN_PLAN_EN") {
      if (values.email == "") {
        delete values.email;
      }else{
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
          errors.email = "ອີເມວບໍ່ຖືກຕ້ອງຕາມຮູບແບບ";
        }
      }
      if (values.phoneHome == "") {
        delete values.phoneHome;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }
      if(values.scholarshipFrom === "") {
        delete values.scholarshipFrom
      }
      if(values.outPlanScholarship === "") {
        delete values.outPlanScholarship
      }

      if(!values.scholarship) {
        errors.scholarship = "Please select scholarship"
      }
      if(!values.graduateFromCountry) {
        errors.graduateFromCountry = "Please select graduate country"
      }
      if(!values.graduateFromProvince) {
        errors.graduateFromProvince = "Please input graduate province/state"
      }
      if(!values.graduateFromSchool) {
        errors.graduateFromSchool = "Please input graduate school"
      }
      if(!values.semesterYear) {
        errors.semesterYear = "Please input academic year"
      }
      
      delete values.outPlanGraduateFromCountry;
      delete values.outPlanGraduateFromProvince;
      delete values.outPlanGraduateFromSchool;
      delete values.outPlanSemesterYear;
    }
    delete values.examCode;
    // if (values.email == "") {
    //   delete values.email;
    // }
    if (values.phoneHome == "") {
      delete values.phoneHome;
    }
    if (values.lastNameL == "") {
      delete values.lastNameL;
    }
    delete values.explainNotToStayTogether;
    delete values.otherScholarship;
    delete values.stayTogether;
    delete values.acceptTerms;

    if (studentType == "OUT_PLAN_EN") {
      if (values.email == "") {
        delete values.email;
      }else{
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
          errors.email = "ອີເມວບໍ່ຖືກຕ້ອງຕາມຮູບແບບ";
        }
      }
      if (values.phoneHome == "") {
        delete values.phoneHome;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      delete values.graduateFromCountry;
      delete values.graduateFromProvince;
      delete values.graduateFromSchool;
      // delete values.scholarship;
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      delete values.semesterYear;
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }

      if(!values.outPlanScholarship) {
        errors.outPlanScholarship = "Please select scholarship"
      }
      if(!values.outPlanGraduateFromCountry) {
        errors.outPlanGraduateFromCountry = "Please select graduate country"
      }
      if(!values.outPlanGraduateFromProvince) {
        errors.outPlanGraduateFromProvince = "Please input graduate province/state"
      }
      if(!values.outPlanGraduateFromSchool) {
        errors.outPlanGraduateFromSchool = "Please input graduate school"
      }
      if(!values.outPlanSemesterYear) {
        errors.outPlanSemesterYear = "Please input academic year"
      }
    }
    if (stayTogether == true) {
    }

    if(!values.studentInfo) {
      errors.studentInfo = "Please select student's information"
    }
    if(!values.educationLevel) {
      errors.educationLevel = "Please select level"
    }
    if(!values.firstNameE) {
      errors.firstNameE = "Please input given name"
    }
    if(!values.lastNameE) {
      errors.lastNameE = "Please input family name"
    }
    if(!values.nationality) {
      errors.nationality = "Please select nationality"
    }
    if(!values.religion) {
      errors.religion = "Please select religion"
    }
    if(!values.phoneMobile) {
      errors.phoneMobile = "Please input your mobile phone"
    }
    if(!values.country) {
      errors.country = "Please input current country"
    }
    if(!values.province) {
      errors.province = "Please input current province/state"
    }
    if(!values.district) {
      errors.district = "Please input current district"
    }
    if(!values.village) {
      errors.village = "Please input current village"
    }
    if(!values.birthCountry) {
      errors.birthCountry = "Please select birth country"
    }
    if(!values.birthProvince) {
      errors.birthProvince = "Please input birth province/state"
    }
    if(!values.birthDistrict) {
      errors.birthDistrict = "Please input birth district"
    }
    if(!values.birthVillage) {
      errors.birthVillage = "Please input birth village"
    }
    if(!values.fatherNameAndSurname) {
      errors.fatherNameAndSurname = "Please input father name and surname"
    }
    if(!values.motherNameAndSurname) {
      errors.motherNameAndSurname = "Please input mother name and surname"
    }
    if(!values.parentTelephone) {
      errors.parentTelephone = "Please input parents mobile phone"
    }
    if(!values.parentCountry) {
      errors.parentCountry = "Please select parents country"
    }
    if(!values.parentProvince) {
      errors.parentProvince = "Please input parent province/state"
    }
    if(!values.parentDistrict) {
      errors.parentDistrict = "Please input parents district"
    }
    if(!values.parentVillage) {
      errors.parentVillage = "Please input parent village"
    }
    if(!values.contactName) {
      errors.contactName = "Please input contact name"
    }
    if(!values.emergencyPhone) {
      errors.emergencyPhone = "Please input emergency phone"
    }
    if(!passportUrl) {
      errors.passportUrl = "Please choose file"
    }
    if(!qualificationUrl) {
      errors.qualificationUrl = "Please choose file"
    }
    setErrorInput(errors)
    console.log(errors)

    let address = {
      country: values.country,
      province: values.province,
      district: values.district,
      village: values.village,
    };
    values = {
      ...values,
      address: address,
    };
    delete values.country;
    delete values.province;
    delete values.village;
    delete values.district;

    let birthAddress = {
      country: values.birthCountry,
      province: values.birthProvince,
      district: values.birthDistrict,
      village: values.birthVillage,
    };
    values = {
      ...values,
      birthAddress: birthAddress,
    };
    delete values.birthCountry;
    delete values.birthProvince;
    delete values.birthVillage;
    delete values.birthDistrict;

    let parentAddress = {
      country: values.parentCountry,
      province: values.parentProvince,
      district: values.parentDistrict,
      village: values.parentVillage,
    };
    values = {
      ...values,
      parentAddress: parentAddress,
    };
    delete values.parentCountry;
    delete values.parentProvince;
    delete values.parentVillage;
    delete values.parentDistrict;
    let data = {
      ...values,
      yearLevel: "1",
      examCode: dataLocalStorages?.id,
      faculty: facultyApollo?.slmsFaculties[selectFacultyIndex]?.id,
      department: values.department,
      facultyname:
        facultyApollo?.slmsFaculties[selectFacultyIndex]?.name,
      departmentname: departmentName,
      // tribe: values.tribe,
      passportUrl: { url: passport },
      qualificationUrl: { url: qualification },
      otherUrl: { url: other },
      // scorecardUrl: { url: scorecardUrl },
      // phoneHome: student?.phoneHome,
      studentType: studentType,
      statusStep: student?.statusStep ?? "SEND_FORM",
      statusStepUpdate: student?.statusStep,
      scholarship: studentType === "OUT_PLAN_EN"
        ? values.outPlanScholarship
        : values.scholarship,
      graduateFromCountry: values.outPlanGraduateFromCountry
        ? values.outPlanGraduateFromCountry
        : values.graduateFromCountry,
      graduateFromProvince: values.outPlanGraduateFromProvince
        ? values.outPlanGraduateFromProvince
        : values.graduateFromProvince,
      graduateFromSchool: values.outPlanGraduateFromSchool
        ? values.outPlanGraduateFromSchool
        : values.graduateFromSchool,
      semesterYear: values.outPlanSemesterYear
        ? values.outPlanSemesterYear
        : values.semesterYear,
      id: student?.id,
    };
    delete data.outPlanScholarship;
    delete data.outPlanGraduateFromCountry;
    delete data.outPlanGraduateFromProvince;
    delete data.outPlanGraduateFromSchool;
    delete data.outPlanSemesterYear;
    delete data.birthDistrict;
    delete data.birthVillage;
    delete data.district;
    delete data.village;
    
    if (student) {
      data.id = student.id;
    }
    let paramQL = {
      data,
    };
    setFormParam(paramQL);
    const checkData = _.isEmpty(errors)
    if(!checkData) {
      setShowDanger(true)
      setTimeout(() => {
        setShowDanger(false)
      }, 2500);
      return
    }
    if (type == "save") {
      try {
        if (student) {
          delete data.facultyname;
          delete data.departmentname;
          delete data.statusStepUpdate;
          delete data.statusStep;
          // setShow(true);
          const updateData = await updateStudent({
            variables: {
              where: { id: student?.id },
              data: data,
            },
          });
          if(updateData) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
        if (!student) {
          delete data.facultyname;
          delete data.departmentname;
          delete data.statusStep;
          // setShow(true);
          const createData = await createStudent({ variables: paramQL });
          if(createData) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
      } catch (err) { }
    } else if (type == "verify") {
      _handleShowAddConfirmModalShow();
    }
  }

  if(loading) return (<Loading />)
  return (
    <div className="container mt-5">
      <div className="col-sm-12 card p-4" style={{ color: "#707070" }}>
        {/* {studentData && */}
        {
          <Formik
            enableReinitialize={true}
            initialValues={{
              examCode: dataLocalStorages?.id ?? "",
              faculty: student?.faculty?.id ?? dataLocalStorages?.faculty?.id,
              department:
                student?.department?.id ?? dataLocalStorages?.department?.id,
              code: renderCode,
              studentNumber: getCode,
              studentInfo: student?.studentInfo ?? "",
              educationLevel: student?.educationLevel ?? "",
              // firstNameL: student?.firstNameE ?? dataLocalStorages?.firstNameE,
              // lastNameL: student?.lastNameE ?? dataLocalStorages?.lastNameE,
              firstNameL: student?.firstNameL ?? dataLocalStorages?.firstNameL,
              lastNameL: student?.lastNameL ?? dataLocalStorages?.lastNameL,
              firstNameE: student?.firstNameE ?? dataLocalStorages?.firstNameE,
              lastNameE: student?.lastNameE ?? dataLocalStorages?.lastNameE,
              birthday:
                student?.birthday ??
                dataLocalStorages?.birthday ??
                "1990-01-01",
              dateTransferLetterNo:
                formatDateYYMMDD(student?.dateTransferLetterNo) ?? "",
              dateScholarshipAgreementNo:
                formatDateYYMMDD(student?.dateScholarshipAgreementNo) ?? "",
              gender: student?.gender ?? "MALE",
              maritualStatus: student?.maritualStatus ?? "SINGLE",
              nationality: student?.nationality ?? "",
              // tribe: student?.tribe?.id ?? "",
              religion: student?.religion ?? "",
              phoneHome: student?.phoneHome ?? "",
              phoneMobile:
                student?.phoneMobile ?? dataLocalStorages?.phoneMobile,
              email: student?.email ?? "",
              country: student?.address?.country ?? "",
              province: student?.address?.province ?? "",
              district: student?.address?.district ?? "",
              village: student?.address?.village ?? "",
              birthCountry:
                student?.birthAddress?.country ??
                dataLocalStorages?.birthAddress?.country,
              birthProvince:
                student?.birthAddress?.province ??
                dataLocalStorages?.birthAddress?.province,
              birthDistrict:
                student?.birthAddress?.district ??
                dataLocalStorages?.birthAddress?.district,
              birthVillage:
                student?.birthAddress?.village ??
                dataLocalStorages?.birthAddress?.village,
              parentCountry: student?.parentAddress?.country ?? "",
              parentProvince: student?.parentAddress?.province ?? "",
              parentDistrict: student?.parentAddress?.district ?? "",
              parentVillage: student?.parentAddress?.village ?? "",
              scholarship: student?.scholarship ?? "",
              scholarshipFrom: student?.scholarshipFrom ?? "",
              outPlanScholarship: student?.scholarship ?? "",
              otherScholarship: student?.otherScholarship ?? "",
              graduateFromCountry: student?.graduateFromCountry ?? "",
              graduateFromProvince: student?.graduateFromProvince ?? "",
              graduateFromSchool: student?.graduateFromSchool ?? "",
              semesterYear: student?.semesterYear ?? "",
              outPlanGraduateFromCountry: student?.graduateFromCountry ?? "",
              outPlanGraduateFromProvince: student?.graduateFromProvince ?? "",
              outPlanGraduateFromSchool: student?.graduateFromSchool ?? "",
              outPlanSemesterYear: student?.semesterYear ?? "",
              contactName:
                student?.contactName ?? dataLocalStorages?.contactName,
              emergencyPhone:
                student?.emergencyPhone ?? dataLocalStorages?.emergencyPhone,
              stayTogether: student?.stayTogether ?? false,
              acceptTerms: student?.acceptTerms ?? false,
              explainNotToStayTogether: student?.explainNotToStayTogether ?? "",
              dormitory: student?.dormitory ?? "YES",
              fatherNameAndSurname: student?.fatherNameAndSurname ?? "",
              motherNameAndSurname: student?.motherNameAndSurname ?? "",
              parentTelephone: student?.parentTelephone ?? "",
              transferLetterNo: student?.transferLetterNo ?? "",
              scholarshipAgreementNo: student?.scholarshipAgreementNo ?? "",
            }}
            validate={(values) => {
              
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={(event) => handleSubmit2(event, values)}>
                <div className="card_registrationStudents">
                  <div className="card-body card_registerstrationStudents_body">
                    <h6 className="mt-3 textForm">
                      Registration Form
                      <span className="float-right">
                        <a
                          href="https://nuol.s3.ap-southeast-1.amazonaws.com/pregistration-image/%E0%BA%84%E0%BA%B9%E0%BB%88%E0%BA%99%E0%BA%B3%E0%BB%83%E0%BA%8A%E0%BB%89-%E0%BA%A5%E0%BA%BB%E0%BA%87%E0%BA%97%E0%BA%B0%E0%BA%9A%E0%BA%BD%E0%BA%99%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99+%E0%BA%A1%E0%BA%8A.pdf"
                          target="_blank"
                        >
                          <u>Manual</u>
                        </a>
                      </span>
                    </h6>
                    <div className="card bg-register">Student Information</div>

                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Registration Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Enter your registration number"
                          name="studentNumber"
                          value={values.studentNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Faculty <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="faculty"
                          isInvalid={errors.faculty}
                          value={values.faculty}
                          onChange={(e) => {
                            handleChange(e);
                            _selectFacalty(e);
                          }}
                          disabled
                        >
                          <option disabled={false} value="">
                            ---Select---
                          </option>
                          {facultyApollo?.slmsFaculties.map((x, index) => (
                            <option key={"faculty" + index} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Major
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="department"
                          isInvalid={errors.department}
                          value={values.department}
                          onChange={(e) => {
                            handleChange(e);
                            _selectDepartment(e);
                          }}
                          disabled
                        >
                          <option value="">---Select---</option>
                          {departmentPricesData &&
                            departmentPricesData?.map((x, index) => (
                              <option key={"department" + index} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold" name="">
                          Student's information{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="studentInfo"
                          value={values.studentInfo}
                          isInvalid={errorInput && errorInput?.studentInfo}
                          onChange={handleChange}
                        >
                          <option value="">---Select---</option>
                          <option value="EMPLOYEE">Employed</option>
                          <option value="ORDINARY_STUDENT">
                            Normal Student
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Level <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.educationLevel}
                          name="educationLevel"
                          isInvalid={errorInput && errorInput?.educationLevel}
                          onChange={handleChange}
                        >
                          <option value="">---Select---</option>
                          <option value="DEPLOMA">Associate Degree</option>
                          <option value="BACHELOR_DEGREE">Bachelor Degree</option>
                          <option value="BACHELOR_CONTINUING">
                            Continuing Bachelor Degree
                          </option>
                        </Form.Control>
                      </div>
                    </div>
                    <div className="card bg-register">General Information</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Given name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.firstNameE}
                          placeholder="Enter your given name"
                          name="firstNameE"
                          disabled={dataLocalStorages?.firstNameE ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.firstNameE}
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Family name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.lastNameE}
                          placeholder="Enter your family name"
                          name="lastNameE"
                          disabled={dataLocalStorages?.lastNameE ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.lastNameE}
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          Date of birth <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={values.birthday}
                          placeholder="12/02/2000"
                          name="birthday"
                          onChange={handleChange}
                          isInvalid={errors.birthday}
                          disabled={dataLocalStorages?.birthday ? true : false}
                        />
                      </div>
                      {/* <div className="col-md-6 mt-3" /> */}
                      <div className="col-xs-12 col-md-2 mt-5">
                        <label className="text-bold">
                          Gender <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-5">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="Male"
                            type="radio"
                            value="MALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "MALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-5">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="Female"
                            type="radio"
                            value="FEMALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "FEMALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3" />
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          Marital status <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="Single"
                            type="radio"
                            value="SINGLE"
                            id="maritualStatus-radio"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "SINGLE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="Married"
                            type="radio"
                            id="maritualStatus-radio"
                            value="MARRIAGE"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "MARRIAGE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3" />
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          Dormitory <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="In dormitory"
                            type="radio"
                            value="YES"
                            id="dormitory-radio"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory == "YES" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-12 col-md-6 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="Off dormitory"
                            type="radio"
                            id="dormitory-radio"
                            value="NO"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory == "NO" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Nationality <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.nationality}
                          name="nationality"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.nationality}
                        >
                          <option value="">---Select---</option>
                          {COUNTRIES_LIST.map((nationalities, index) => (
                            <option
                              key={"nationalities" + index}
                              value={nationalities?.nationality}
                            >
                              {nationalities?.nationality}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Religion <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.religion}
                          name="religion"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.religion}
                        >
                          <option value="">---Select---</option>
                          <option value="​ພຸດ">​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                          <option value="ຜີ">ສາ​ສະ​ໜາຜີ</option>
                          <option value="ຄຣິສະ​ຕຽນ">ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ</option>
                          <option value="ອິດສະລາມ">
                            ສາ​ສະ​ໜາອິດສະລາມ
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Home phone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.phoneHome}
                          placeholder="Please input your home phone"
                          name="phoneHome"
                          isInvalid={errors.phoneHome}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Mobile phone <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.phoneMobile}
                          placeholder="Please input your mobile phone"
                          name="phoneMobile"
                          isInvalid={errorInput && errorInput?.phoneMobile}
                          onChange={handleChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          placeholder="Please input email"
                          name="email"
                          isInvalid={errorInput && errorInput?.email}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorInput && errorInput?.email}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="card bg-register">Current address</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Country <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="country"
                          // onChange={(e) => {
                          //   _selectProvince(e);
                          //   handleChange(e);
                          // }}
                          onChange={handleChange}
                          value={values.country}
                          isInvalid={errorInput && errorInput?.country}
                          id="disabledSelect"
                        >
                          <option value="">---Select---</option>
                          {COUNTRIES_LIST.map((countries, index) => (
                            <option
                              key={"countries" + index}
                              value={countries?.en_short_name}
                            >
                              {countries?.en_short_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Province/State <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="province"
                          isInvalid={errorInput && errorInput?.province}
                          placeholder="Please input province"
                          onChange={handleChange}
                          value={values.province}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          District <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="district"
                          isInvalid={errorInput && errorInput?.district}
                          placeholder="Please input district"
                          onChange={handleChange}
                          value={values.district}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Vilage <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="village"
                          isInvalid={errorInput && errorInput?.village}
                          placeholder="Please input village"
                          onChange={handleChange}
                          value={values.village}
                        />
                      </div>
                    </div>
                    <div className="card bg-register">Place of Birth</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Country <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="birthCountry"
                          // onChange={(e) => {
                          //   _selectBirthProvince(e);
                          //   handleChange(e);
                          // }}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.birthCountry}
                          value={values.birthCountry}
                        // disabled
                        >
                          <option value="">---Select---</option>
                          {COUNTRIES_LIST.map((countries, index) => (
                            <option
                              key={"countries" + index}
                              value={countries?.en_short_name}
                            >
                              {countries?.en_short_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Province/State <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="birthProvince"
                          isInvalid={errorInput && errorInput?.birthProvince}
                          placeholder="Please input province"
                          onChange={handleChange}
                          value={values.birthProvince}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          District <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="birthDistrict"
                          value={values.birthDistrict}
                          isInvalid={errorInput && errorInput?.birthDistrict}
                          placeholder="Please input district"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Village <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="birthVillage"
                          value={values.birthVillage}
                          isInvalid={errorInput && errorInput?.birthVillage}
                          placeholder="Please input"
                          onChange={handleChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-4 mt-3" />
                    </div>
                    {studentType !== "IN_PLAN_EN" ? (
                      <div>
                        <div className="card bg-register">
                          For self-financing student
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Scholarship <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              id="disabledSelect"
                              name="outPlanScholarship"
                              value={values.outPlanScholarship}
                              isInvalid={errorInput && errorInput?.outPlanScholarship}
                              onChange={(e) => {
                                selectScholarship(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">Select Scholarship</option>
                              <option value="ORGANIZATION_IN_LAOPDR_MINISTRIES">
                                The organization in the Lao PDR(Ministries)
                              </option>
                              <option value="ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION">
                                The organization in the Lao PDR(Provincial Education Division)
                              </option>
                              <option value="HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD">
                                Higher Education institution form abroad
                              </option>
                              <option value="PERSONAL_FINANCIAL">
                                Personal financial
                              </option>
                            </Form.Control>
                          </div>
                          <div className="col-md-12 mt-3">
                            <Form.Control
                              type={
                                values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_MINISTRIES" 
                                || values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
                                ? "text" : "hidden"
                              }
                              placeholder="Please input scholarship from which organization"
                              value={values.scholarshipFrom}
                              name="scholarshipFrom"
                              isInvalid={errors.scholarshipFrom}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Country <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanGraduateFromCountry"
                              isInvalid={errorInput && errorInput?.outPlanGraduateFromCountry}
                              // onChange={(e) => {
                              //   _selectGraduateFromProvince(e);
                              //   handleChange(e);
                              // }}
                              onChange={handleChange}
                              value={values.outPlanGraduateFromCountry}
                            >
                              <option value="">---Select---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Province/State <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Please input graduate province"
                              value={values.outPlanGraduateFromProvince}
                              name="outPlanGraduateFromProvince"
                              isInvalid={errorInput && errorInput?.outPlanGraduateFromProvince}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Graduate from <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Please input graduate from"
                              value={values.outPlanGraduateFromSchool}
                              name="outPlanGraduateFromSchool"
                              isInvalid={errorInput && errorInput?.outPlanGraduateFromSchool}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Academic year <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanSemesterYear"
                              isInvalid={errorInput && errorInput?.outPlanSemesterYear}
                              onChange={handleChange}
                              value={values.outPlanSemesterYear}
                            >
                              <option>---Select---</option>
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="card bg-register">
                          For student/Civil servant who receives scholarship from an organization
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Scholarship <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              id="disabledSelect"
                              name="scholarship"
                              value={values.scholarship}
                              isInvalid={errorInput && errorInput?.scholarship}
                              onChange={(e) => {
                                selectScholarship(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">Select Scholarship</option>
                              {/* <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ
                              </option>
                              <option value="NUOL_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ
                              </option>
                              <option value="RED_DIPLOMA_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ
                              </option>
                              <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ
                              </option>
                              <option value="EXCHANGE_STUDENT_SCHOLARSHIP">
                                ທຶນ​ແບ່ງ​ນັກ​ຮຽນ​ສາ​ມັນ
                              </option>
                              <option value="OFFICIAL_STAFF_SCHOLARSHIP">
                                ພະ​ນັກ​ງານ
                              </option> */}
                              <option value="NUOL_SCHOLARSHIP">
                                NUOL'S talent student
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                Provincial ethnic student
                              </option>
                              <option value="RED_DIPLOMA_SCHOLARSHIP">
                                First-class honours
                              </option>
                              <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                                Outstanding student in general subject/talent student
                              </option>
                              <option value="MINISTRY_OF_EDUCATION_AND_SPORTS">
                                Ministry of Education and Sports
                              </option>
                              <option value="OTHER_SHOLARSHIP">
                                Other scholarship
                              </option>
                            </Form.Control>
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Country <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="graduateFromCountry"
                              isInvalid={errorInput && errorInput?.graduateFromCountry}
                              // onChange={(e) => {
                              //   _selectGraduateFromProvince(e);
                              //   handleChange(e);
                              // }}
                              onChange={handleChange}
                              value={values.graduateFromCountry}
                            >
                              <option value="">---Select---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Province/State <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Please input graduate province"
                              value={values.graduateFromProvince}
                              name="graduateFromProvince"
                              isInvalid={errorInput && errorInput?.graduateFromProvince}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Graduate from <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Please input graduate from"
                              value={values.graduateFromSchool}
                              name="graduateFromSchool"
                              isInvalid={errorInput && errorInput?.graduateFromSchool}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Academic year <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="semesterYear"
                              isInvalid={errorInput && errorInput?.semesterYear}
                              onChange={handleChange}
                              value={values.semesterYear}
                            >
                              <option>---Select---</option>
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                          
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Transfer letter no
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="transferLetterNo"
                              placeholder="Please input transfer letter no"
                              onChange={handleChange}
                              value={values.transferLetterNo}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={values.dateTransferLetterNo}
                              name="dateTransferLetterNo"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Scholarship agreement no
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Please input scholarship agreement no"
                              name="scholarshipAgreementNo"
                              value={values.scholarshipAgreementNo}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="dateScholarshipAgreementNo"
                              onChange={handleChange}
                              value={values.dateScholarshipAgreementNo}
                            />
                          </div>
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              Other scholarship{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="otherScholarship"
                              placeholder="Please input other scholarship"
                              isInvalid={errors.otherScholarship}
                              onChange={handleChange}
                              value={values.otherScholarship}
                              disabled={
                                scholarship == ""
                                  ? true
                                  : scholarship == "OTHER_SHOLARSHIP"
                                    ? false
                                    : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="card bg-register">Parents's information</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Father name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please input father name"
                          name="fatherNameAndSurname"
                          isInvalid={errorInput && errorInput?.fatherNameAndSurname}
                          onChange={handleChange}
                          value={values.fatherNameAndSurname}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Mother name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please input mother name"
                          name="motherNameAndSurname"
                          isInvalid={errorInput && errorInput?.motherNameAndSurname}
                          onChange={handleChange}
                          value={values.motherNameAndSurname}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Mobile phone <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please input mobile phone"
                          name="parentTelephone"
                          isInvalid={errorInput && errorInput?.parentTelephone}
                          onChange={handleChange}
                          value={values.parentTelephone}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Country <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="parentCountry"
                          // onChange={(e) => {
                          //   _selectParentProvince(e);
                          //   handleChange(e);
                          // }}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.parentCountry}
                          value={values.parentCountry}
                        >
                          <option value="">---Select---</option>
                          {COUNTRIES_LIST.map((countries, index) => (
                            <option
                              key={"countries" + index}
                              value={countries?.en_short_name}
                            >
                              {countries?.en_short_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Province/State <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="parentProvince"
                          value={values.parentProvince}
                          isInvalid={errorInput && errorInput?.parentProvince}
                          placeholder="Please input province"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          District <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="parentDistrict"
                          value={values.parentDistrict}
                          isInvalid={errorInput && errorInput?.parentDistrict}
                          placeholder="Please input district"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Village <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="parentVillage"
                          value={values.parentVillage}
                          isInvalid={errorInput && errorInput?.parentVillage}
                          placeholder="Please input village"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="card bg-register">Emergency contact</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.contactName}
                          name="contactName"
                          isInvalid={errorInput && errorInput?.contactName}
                          placeholder="Please input name"
                          onChange={handleChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Mobile phone <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.emergencyPhone}
                          name="emergencyPhone"
                          isInvalid={errorInput && errorInput?.emergencyPhone}
                          placeholder="​Please input mobile phone"
                          onChange={handleChange}
                        // disabled
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <Form.Group
                          controlId="formBasicCheckbox"
                          style={{ marginTop: 25 }}
                        >
                          <Form.Check
                            type="checkbox"
                            label="Same address"
                            name="stayTogether"
                            value={values.stayTogether}
                            onChange={handleChange}
                            onClick={() => _clickStayTogether()}
                            checked={values.stayTogether === true ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          Note: if not staying together. Please input the address below
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.explainNotToStayTogether}
                          isInvalid={errors.explainNotToStayTogether}
                          name="explainNotToStayTogether"
                          placeholder="Please input address"
                          onChange={handleChange}
                          disabled={stayTogether == true ? true : false}
                        />
                      </div>
                    </div>
                    <div className="card bg-register">
                      <label>
                        Upload document <span className="text-danger">*</span>
                      </label>
                    </div>
                    {/* <div style={{ marginTop: 10 }}>ສາມາດອັບໂຫຼດຟາຍຮູບໄດ້ສະເພາະນາມສະກຸນ: .png, .heif, .jpeg, .jpg, .pdf ເທົ່ານັ້ນ</div> */}
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        Passport{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput?.passportUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload"
                            onChange={_handleChangeFile}
                            hidden
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onClick={() => setClick("1")}
                          />
                          <label
                            htmlFor="file-upload"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click == 1 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />}
                            </div>
                          </label>
                          <span>Choose file</span>
                          {passport && !isLoading && (
                            <aside>
                              <h4>Your file</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {passport}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setPassport(null);
                                    setPassportUrl(null);
                                  }}
                                  variant={"danger"}
                                >
                                  Delete file
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        Qualification achieved abroad{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput?.qualificationUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload3"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("2")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload3"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click == 2 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />}
                            </div>
                          </label>
                          <span>Choose file</span>
                          {qualification && !isLoading && (
                            <aside>
                              <h4>Your file</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {qualification}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setQualification(null);
                                    setQualificationUrl(null);
                                  }}
                                  variant={"danger"}
                                >
                                  Delete file
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        Other{" "}
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload4"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("3")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload4"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click == 3 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />}
                            </div>
                          </label>
                          <span>Choose file</span>
                          {other && !isLoading && (
                            <aside>
                              <h4>Your file</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {other}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setOther(null);
                                    setOtherUrl(null);
                                  }}
                                  variant={"danger"}
                                >
                                  Delete file
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="d-flex justify-content-center fixed-bottom button-submit-registerStudent"
                      style={{
                        backgroundColor: "#fff",
                        padding: 10,
                      }}
                    >
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                          className="buttonoutline"
                          onClick={() => setShowConfirmLogout(true)}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          type="submit"
                          className="btn btn-success"
                          onClick={() => {
                            setType("save");
                            // handleSubmit()
                          }}
                          style={{ backgroundColor: "#1B8900", color: "#fff" }}
                          disabled={isSave == true ? false : (isLoading ? true : false)}
                        >
                          Save
                        </Button>
                      </div>
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={() => {
                            setType("verify");
                            // handleSubmit();
                          }}
                          disabled={isSave == true ? true : (isLoading ? true : false)}
                        >
                          Send <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        }
      </div>
      <Modal
        show={show}
        onHide={() => isSaving ? console.log("Saving") : setShow(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {isSaving ? <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          </Col> : <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size40 text-bold"
              style={{ color: "#00A1DE" }}
            >
              Successfully
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              Your information have saved
            </div>
          </Col>}
        </Modal.Body>
      </Modal>

      <Modal show={showDanger} onHide={() => setShowDanger(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div className="textfont-size40 text-bold" style={{ color: "red" }}>
              Incomplete information!
            </div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="5x"
              style={{ color: "red" }}
            />
            <div
              className="textfont-size18 text-bold"
              style={{ marginTop: 40 }}
            >
              Check your information!
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ marginTop: 50 }}
        show={showConfirmLogout}
        onHide={() => setShowConfirmLogout(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>
              Exit
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              // className="text-resize5"
              style={{ fontWeight: "bold", fontSize: 24, color: "#6f6f6f" }}
            >
              Are you sure?
            </div>
          </Col>
          <Col className="d-flex justify-content-between">
            <div />
            <CustomButton
              onClick={() => setShowConfirmLogout(false)}
              type="submit"
              title="Cancel"
            />

            <CustomButton
              confirm
              onClick={() => _onLogout()}
              type="submit"
              title="Yes, I'm sure"
            />
            <div />
          </Col>
        </Modal.Body>
      </Modal>
      <RegistrationConfirmEn
        showAddConfirmModal={showAddConfirmModal}
        studentType={studentType}
        param={formParam}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
      />
    </div>
  );
}
