import { gql } from 'apollo-boost'
export const STUDENTS = gql`
  query Students($where: studentWhereInput, $orderBy: studentOrderByInput) {
    students(where: $where, orderBy: $orderBy) {
      data {
        id
        studentNumber
        examNumber
        examCode
        faculty {
          id
          name
        }
        department {
          id
          name
        }
        major {
          id
          name
        }
        dateTransferLetterNo
        dateScholarshipAgreementNo
        transferLetterNo
        scholarshipAgreementNo
        fatherNameAndSurname
        motherNameAndSurname
        parentTelephone
        dormitory
        code
        studentInfo
        statusStep
        educationLevel
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        birthday
        gender
        maritualStatus
        nationality
        tribe {
          id
          name
        }
        religion
        phoneHome
        phoneMobile
        email
        address {
          country
          province
          district
          village
        }
        birthAddress {
          country
          province
          district
          village
        }
        parentAddress {
          country
          province
          district
          village
        }
        scholarship
        otherScholarship
        # assessmentYear
        # scholarshipFrom
        graduateFromCountry
        graduateFromProvince
        graduateFromSchool
        semesterYear
        contactName
        emergencyPhone
        stayTogether
        acceptTerms
        explainNotToStayTogether
        idCardOrFamilyBookUrl {
          url
        }
        scorecardUrl {
          url
        }
        certificateUrl {
          url
        }
        testCardUrl {
          url
        }
        deliveryCardUrl {
          url
        }
        passportUrl {
          url
        }
        qualificationUrl{
          url
        }
        otherUrl{
          url
        }
        imageProfile
        studentType
        createdAt
        createdBy
        updatedAt
        updatedBy
        note
        payment {
          id
          paymentId
          mcId
          ccy
          merchantName
          paymentName
          phone
          paymentTime
          amount
          description
          status
          createdAt
        }
      }
    }
  }
`

export const TRIBES = gql`
  query Tribes($where: tribeInput) {
    tribes(where: $where) {
      id
      name
    }
  }
`

export const DEPARTMENT_PRICES = gql`
  query DepartmentWithPrices(
    $where: DepartmentWithPriceInput
    $skip: Int
    $limit: Int
    $orderBy: DepartmentWithPriceOrderByInput
  ) {
    departmentWithPrices(
      where: $where
      skip: $skip
      limit: $limit
      orderBy: $orderBy
    ) {
      id
      name
      code
      unitPrice
      semesterOne
      semesterTwo
      registerPrice
      price
      status
    }
  }
`
export const DEPARTMENT_PRICE = gql`
  query DepartmentWithPrice(
    $where: DepartmentWithPriceWhereInputId!
  ) {
    departmentWithPrice(
      where: $where
    ) {
      id
      name
      code
      unitPrice
      semesterOne
      semesterTwo
      registerPrice
      price
      status
    }
  }
`
