import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Col, Row } from 'react-bootstrap'
import {VERSION} from '../../consts'
import morjorImage from '../../images/morjor.jpg'
import logo from '../../images/morjor_logo.png'
import jcb from '../../images/JCB.gif'
import Visa from '../../images/verified_Visa.png'
import mastercard from '../../images/mastercard.png'
import pngkit_call from '../../images/pngkit_call.png'
import safekey from '../../images/safekey.png'

import useReactRouter from 'use-react-router'
import { BrowserView, MobileView } from 'react-device-detect'
import { faSignInAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
export default function Login () {
  const { history } = useReactRouter()
  const [showDanger, setShowDanger] = useState(false)
  const [showModalCloseRegister, setShowModalCloseRegister] = useState(false)

  const _handleClickOutplan = () => {
    // open register
    // history.push('/login-from', { typeForm: 'OUT_PLAN' })

    setShowModalCloseRegister(true)
  }
  const _handleClickInplan = () => {
    // open register
    // history.push('/login-from', { typeForm: 'IN_PLAN' })

    setShowModalCloseRegister(true)
  }

  // const _handleClickLao = () => {
  //   history.push('/login-from')
  // }
  // const _handleClickEnglish = () => {
  //   history.push('/login-from-en')
  // }
  return (
    <div>
      <BrowserView>
        <div style={{ display: 'flex' }}>
          <div className='col-sm-6 cardCustom'>
            <img src={morjorImage} className='img' />
            <div className='topleft'></div>
            <div className='textdev'>
              <img src={logo} className='logo' />
              <div className='textfont-size24 colorWhite'>
                ມະຫາວິທະຍາໄລ ຈຳປາສັກ <br />
                Champasack University
              </div>
              <div className='textfont-size50 colorWhite paddings'>
                ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ເຂົ້າ​ສູ່​ເວັບ​ໄຊ​ທ໌
                <br /> ການ​ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
              </div>
            </div>
          </div>
          <div className='col-sm-6 left-content'>
            <div
              style={{
                textAlign: 'right',
                marginRight: 23,
                marginTop: 24,
                fontSize: 24
              }}
            >
              ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?
              <a href='#' onClick={() => setShowDanger(true)}>
                ຊ່ວຍ​ເຫຼືອ
              </a>
              <br />
              <span style={{fontSize: 12}}>{VERSION}</span>
            </div>

            <div className='text-center'>
              <div className='textfont-size30 text-bold'>
                ເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ
              </div>
              <div className='textfont-size20'>
                ກະ​ລຸ​ນາ, ເລືອກ​ຮູບ​ແບບ​ການລ໋ອກ​ອິນ​ໃຫ້​ຖຶກ​ຕ້ອງ
              </div>
            </div>

            <div className='mt-3' style={{padding: '0 100px'}}>
              <Row>
                <Col className='text-bold'>ວັນທີເປີດ/ປິດລົງທະບຽນ:</Col>
                <Col className='text-danger'>19 ກັນຍາ 2023 - 06 ຕຸລາ 2023</Col>
              </Row>
            </div>
            
            <div className='mt-2' style={{padding: '0 100px'}}>
              <div
                // className='d-flex justify-content-center'
                onClick={() => _handleClickOutplan()}
              >
                <div style={{ cursor: 'pointer' }} className='login2'>
                  <div className='col-sm-9'>
                    <div className='textfont-size26 colorWhite'>
                      ລົງທະບຽນເຂົ້າຮຽນ
                    </div>
                    <div className='textfont-size30 text-bold colorWhite'>
                      ສ​ຳລັບນັກ​ສຶກ​ສານອກແຜນ
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'right', marginTop: 20, marginLeft: 30 }}
                    className='col-sm-1 colorWhite'
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size='3x' />
                  </div>
                </div>
              </div>
              <div
                // className='d-flex justify-content-center'
                onClick={() => _handleClickInplan()}
              >
                <div style={{ cursor: 'pointer' }} className='login1'>
                  <div className='col-sm-9'>
                    <div className='textfont-size26 colorWhite'>
                      ລົງທະບຽນເຂົ້າຮຽນ
                    </div>
                    <div className='textfont-size30 text-bold colorWhite'>
                      ສ​ຳລັບນັກ​ສຶກ​ສາໃນແຜນ
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'right', marginLeft: 30 }}
                    className='col-sm-1 colorWhite'
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size='3x' />
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-2' style={{padding: '0 100px'}}>
              <Col lg={12} className='mt-2'>
                <p className='text-bold'>ໝາຍເຫດ: </p>ລະບົບລົງທະບຽນເຂົ້າຮຽນອອນລາຍ ເປັນພຽງລະບົບທີ່ອຳນວນຄວາມສະດວກໃຫ້ນັກສຶກສາ ສາມາດປ້ອນແບບຟອມຜ່ານຊ່ອງທາງອອນລາຍ ເພື່ອປະຢັດເວລາໃນການລົງທະບຽນ. ຢ່າງໃດກໍ່ຕາມ, ນັກສຶກສາຈະຕ້ອງໄດ້ຂື້ນມາສະເໜີໂຕນຳວິຊາການລົງທະບຽນ ເພື່ອກວດກາເອກະສານຕົວຈິງ ແລະ ຈະຕ້ອງຈ່າຍຄ່າລົງທະບຽນຮຽນໃຫ້ຄົບຖ້ວນ ກ່ອນມື້ປິດລົງທະບຽນຕົວຈິງ ຈຶ່ງຈະສຳເລັດຂັ້ນຕອນການລົງທະບຽນຮຽນ ແລະໄດ້ຮັບບັດນັກສຶກສາຊົ່ວຄາວ ບັນຈຸເຂົ້າເປັນນັກສຶກສາ ຂອງມະຫາວິທະຍາໄລຈຳປາສັກ.
                </Col>
            </div>
            <div style={{paddingBottom: 20}}>
              <div className='text-center textfont-size18'>
                ລິຂະສິດ
                <br /> Copyright © 2022 Champasack University
                <br /> All Rights Reserved.
                <br />
                <br />
              </div>

              <div className='text-center'>
                <img
                  src={mastercard}
                  style={{ width: 50, height: 20, marginRight: 20 }}
                />
                <img
                  src={Visa}
                  style={{ width: 50, height: 20, marginRight: 20 }}
                />
                <img
                  src={jcb}
                  style={{ width: 40, height: 20, marginRight: 20 }}
                />
                <img src={safekey} style={{ width: 80, height: 20 }} />
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div style={{ display: 'flex' }}>
          <div className='col-sm-12 cardCustom'>
            <img src={morjorImage} className='img' />
            <div className='topleft'></div>
            <div className='mobile-content'>
              <div style={{fontSize: 12, color: "#fff"}}>
                <img src={logo} style={{ width: 80 }} />
                <br />
                ມະຫາວິທະຍາໄລ ຈຳປາສັກ <br />
                Champasack University
              </div>
              <div style={{ fontSize: 16, paddingTop: 50, color: '#fff' }}>
                <h3>
                  ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ເຂົ້າ​ສູ່​ເວັບ​ໄຊ​ທ໌
                  <br /> ການ​ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
                </h3>
                <Row className='mt-5'>
                  <Col className='text-bold'>ວັນທີເປີດ/ປິດລົງທະບຽນ:</Col>
                  <Col className='text-bold'>19 ກັນຍາ 2023 - 06 ຕຸລາ 2023</Col>
                </Row>
              </div>

              <div
                className='d-flex justify-content-center'
                onClick={() => _handleClickOutplan()}
              >
                <div
                  className='loginMobileViewOutPlan col-md-5 col-10'
                  style={{ cursor: 'pointer' }}
                >
                  <div className='col-10'>
                    <div className='textfont-size12 colorWhite'>
                      ລົງທະບຽນເຂົ້າຮຽນ
                    </div>
                    <div className='textfont-size16 text-bold colorWhite'>
                      ສ​ຳລັບນັກ​ສຶກ​ສານອກແຜນ
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'right'}}
                    className='col-2 colorWhite'
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size='2x' />
                  </div>
                </div>
              </div>
              <div
                className='d-flex justify-content-center mt-3'
                onClick={() => _handleClickInplan()}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  className='loginMobileViewInPlan col-md-5 col-10'
                >
                  <div className='col-10'>
                    <div className='textfont-size12 colorWhite'>
                      ລົງທະບຽນເຂົ້າຮຽນ
                    </div>
                    <div className='textfont-size16 text-bold colorWhite'>
                      ສ​ຳລັບນັກ​ສຶກ​ສາໃນແຜນ
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'right', marginTop: 10}}
                    className='col-2 colorWhite'
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size='2x' />
                  </div>
                </div>
              </div>

              <div className='text-white'>
                <Row>
                  <Col lg={12} className='mt-2'>
                    <p className='text-bold'>ໝາຍເຫດ: </p>
                    ລະບົບລົງທະບຽນເຂົ້າຮຽນອອນລາຍ ເປັນພຽງລະບົບທີ່ອຳນວນຄວາມສະດວກໃຫ້ນັກສຶກສາ ສາມາດປ້ອນແບບຟອມຜ່ານຊ່ອງທາງອອນລາຍ ເພື່ອປະຢັດເວລາໃນການລົງທະບຽນ. ຢ່າງໃດກໍ່ຕາມ, ນັກສຶກສາຈະຕ້ອງໄດ້ຂື້ນມາສະເໜີໂຕນຳວິຊາການລົງທະບຽນ ເພື່ອກວດກາເອກະສານຕົວຈິງ ແລະ ຈະຕ້ອງຈ່າຍຄ່າລົງທະບຽນຮຽນໃຫ້ຄົບຖ້ວນ ກ່ອນມື້ປິດລົງທະບຽນຕົວຈິງ ຈຶ່ງຈະສຳເລັດຂັ້ນຕອນການລົງທະບຽນຮຽນ ແລະໄດ້ຮັບບັດນັກສຶກສາຊົ່ວຄາວ ບັນຈຸເຂົ້າເປັນນັກສຶກສາ ຂອງມະຫາວິທະຍາໄລຈຳປາສັກ.
                  </Col>
                </Row>
                <div style={{fontSize: 12, marginTop: 30}}>
                  ລິຂະສິດ
                  <br /> Copyright © 2021 Champasack University
                  <br /> All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      <Modal
        show={showDanger}
        onHide={() => setShowDanger(false)}
        animation={false}
      >
        <Modal.Header className='borderModalLogin'>
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: '#707070',
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          <p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
          <p>ເບີ​ໂທ​ລະ​ສັບ:</p>
          <p>021 771 989</p>
          <p>ວິຊາການ ມຈ:</p>
          <p>020 5445 6359</p>
          <p>020 5252 2266</p>
          <p>ອີ​ເມວ:</p>
          <p>info.01slms@gmail.com</p>
          <p>ໝາຍເຫດ: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
          <img src={pngkit_call} style={{ width: 190, height: 121 }} />
        </Modal.Body>
      </Modal>
      
      <Modal show={showModalCloseRegister} onHide={() => setShowModalCloseRegister(false)} centered>
        <Modal.Body>
          <Col className="text-center p-5" style={{color: "#057CAE"}}>
            <FontAwesomeIcon icon={faInfoCircle} size='8x' />
            <div className="textfont-size30 text-bold mt-5">
              ປິດການລົງທະບຽນແລ້ວ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  )
}
