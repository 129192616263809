import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useReactRouter from "use-react-router";
import { URL_IMAGE_MEDIUM, URL_IMAGE } from "../../consts/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import {
  onConvertGender,
  onConvertScholarship,
  onConvertEducationLevel,
  onConvertGenderEn,
  convertGender
} from "../../common/super";
import Loading from "../../common/Loading"
import "./index.css";

export default function RegistrationStudentInfo() {
  const { history, location } = useReactRouter();
  const user = location?.state;
  const [imgShow, setImgShow] = useState(false);
  const [imgUrl, setImgUrl] = useState();

  const _handleClickUpdateStudent = () => {
    history.push(`/registrationStudent`, {code:user?.code, studentType: user?.studentType});
  };

  if(!user) return (<Loading />)
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6" style={{marginTop: 30, float: "left"}} >
          <a 
            href="#"
            style={{color: "#057CAE"}} 
            onClick={() => history.push(`/cardstudent/${user?.code}`, user?.code)}
          >
            ຂໍ້ມູນນັກສຶກສາ
          </a> / {user?.firstNameE ?? "-"}
        </div>
        <div className="2 col-md-6">
          <span style={{ float: "right" }}>
            <Button
              disabled={user?.statusStep === "REJECTED" ? false : true}
              style={{
                marginTop: 30,
                backgroundColor: user?.statusStep === "REJECTED" ? "#057CAE" : "#F5F5F5",
                color: user?.statusStep === "REJECTED" ? "#FFFFFF" : "#D8D8D8",
                border: user?.statusStep === "REJECTED" ? "2px solid #057CAE" : "2px solid #D8D8D8",
              }}
              onClick={_handleClickUpdateStudent}
            >
              <FontAwesomeIcon icon={faUserEdit} /> ແກ້ໄຂ
            </Button>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="card card-detial-student col-xs-12 col-sm-12 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</p>
            <img
              alt=''
              style={{ margin: "0 auto" }}
              src={
                "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
              }
              className="browserImage"
            />
            <div className="row">
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ລະຫັດສອບເສັງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.examNumber ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຄະນະ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.faculty?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສາ​ຂາ​ວິ​ຊາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.major?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.studentInfo === "EMPLOYEE"
                    ? "ພະນັກງານມາຮຽນຕໍ່"
                    : "ນັກຮຽນສາມັນມາຮຽນຕໍ່"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ລະດັບ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user && user
                    ? onConvertEducationLevel(user?.educationLevel)
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ຂໍ້ມູນ​ທົ່ວໄປ</p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">ຊື່</b>
              </div>
              <div className="col-6">
                <span>{onConvertGender(user?.gender)} {user?.firstNameL ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ນາມສະກຸນ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.lastNameL ?? "-"}</span>
              </div>
              <div className="col-6">
                <b className="textStudent text-resize4">ຊື່ (ພາສາອັງກິດ)</b>
              </div>
              <div className="col-6">
                <span>{onConvertGenderEn(user?.gender)} {user?.firstNameE ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ນາມສະກຸນ (ພາສາອັງກິດ)</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.lastNameE ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ວັນເດືອນປີເກີດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthday ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເພດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{convertGender(user?.gender) ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສະຖານະ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.maritualStatus === "SINGLE" ? "ໂສດ" : "ແຕ່ງງານແລ້ວ"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສັນຊາດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.nationality ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຊົນເຜົ່າ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.tribe?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສາສະໜາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.religion ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທເຮືອນ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneHome ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທລະສັບມືຖື</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneMobile ?? "-"}</span>
              </div>
              {/* <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.dormitory === "YES"
                    ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                    : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ</p>
            <div className="row">
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ແຂວງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.province ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເມືອງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.district ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ບ້ານ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.village ?? "-"}</span>
              </div>
              
              <p className="text-header text-resize5 mt-3">ສະຖານທີ່ເກີດ</p>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ແຂວງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.province ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເມືອງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.district ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ບ້ານ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.village ?? "-"}</span>
              </div>

              <p className="text-header text-resize5 mt-3">ກໍລະນີສຸກເສີນຕິດຕໍ່</p>
              <div className="col-6">
                <b className="textStudent text-resize4">ພົວພັນທ່ານ</b>
              </div>
              <div className="col-6">
                <span>{user?.contactName ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທຕິດຕໍ່</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.emergencyPhone ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ອາໄສຢູ່ຮ່ວມກັນ?</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            {user && user?.studentType !== "OUT_PLAN" ? (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  ສຳລັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ
                </p>
                <div className="row">
                  <div className="col-6">
                    <b className="textStudent text-resize4">ທຶນແບ່ງປັນ</b>
                  </div>
                  <div className="col-6">
                    <span>{onConvertScholarship(user?.scholarship) ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກໂຮງຮຽນ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ສົກສຶກສາ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຈ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.scholarshipAgreementNo ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເລກທີໃບນຳສົ່ງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.transferLetterNo ?? "-"}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  ສຳລັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                </p>
                <div className="row">
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກໂຮງຮຽນ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ສົກສຶກສາ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            <p
              style={{
                fontSize: 20,
                color: "#057CAE",
                fontWeight: "bold",
              }}
            >
              ເອກະສານຄັດຕິດ
            </p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">ໄຟລຮູບ ບັດ​ປະ​ຈຳ​ຕົວ ຫຼື ສຳ​ມ​ະ​ໂນ​ຄົວ (ພຣະ: ໜັງສືສຸທິ)</b>
              </div>
              <div className="col-6">
                <span>
                  {user?.idCardOrFamilyBookUrl && user?.idCardOrFamilyBookUrl?.url?.split('.').pop() === "pdf" ?
                    <a rel="noreferrer" target="_blank" href={URL_IMAGE + user?.idCardOrFamilyBookUrl?.url}> ເປີດໄຟລ PDF</a>
                    : user?.idCardOrFamilyBookUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <img 
                            alt="" 
                            onClick={() => {
                              setImgShow(true)
                              setImgUrl(URL_IMAGE + user?.idCardOrFamilyBookUrl?.url)
                            }} 
                            src={URL_IMAGE + user?.idCardOrFamilyBookUrl?.url} width="40" height="40" />
                      </div> : <div>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </div>
                  }
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ໄຟລຮູບ ໃບ​ປະ​ກາດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.certificateUrl && user?.certificateUrl?.url?.split('.').pop() === "pdf" ?
                    <a rel="noreferrer" target="_blank" href={URL_IMAGE + user?.certificateUrl?.url}> ເປີດໄຟລ PDF</a>
                    : user?.certificateUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <img 
                            alt="" 
                            onClick={() => {
                              setImgShow(true)
                              setImgUrl(URL_IMAGE + user?.certificateUrl?.url)
                            }} 
                            src={URL_IMAGE + user?.certificateUrl?.url} width="40" height="40" />
                      </div> : <div>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </div>
                  }
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ໄຟລຮູບ ໃບຄະແນນ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.scorecardUrl && user?.scorecardUrl?.url?.split('.').pop() === "pdf" ?
                    <a rel="noreferrer" target="_blank" href={URL_IMAGE + user?.scorecardUrl?.url}> ເປີດໄຟລ PDF</a>
                    : user?.scorecardUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <img 
                            alt="" 
                            onClick={() => {
                              setImgShow(true)
                              setImgUrl(URL_IMAGE + user?.scorecardUrl?.url)
                            }} 
                            src={URL_IMAGE + user?.scorecardUrl?.url} width="40" height="40" />
                      </div> : <div>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </div>
                  }
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ໄຟລຮູບ ບັດ​ເຂົ້າ​ຫ້ອງ​ເສັງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.testCardUrl && user?.testCardUrl?.url?.split('.').pop() === "pdf" ?
                    <a rel="noreferrer" target="_blank" href={URL_IMAGE + user?.testCardUrl?.url}> ເປີດໄຟລ PDF</a>
                    : user?.testCardUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <img 
                            alt="" 
                            onClick={() => {
                              setImgShow(true)
                              setImgUrl(URL_IMAGE + user?.testCardUrl?.url)
                            }} 
                            src={URL_IMAGE + user?.testCardUrl?.url} width="40" height="40" />
                      </div> : <div>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </div>
                  }
                </span>
              </div>
              {
                user?.studentType === "IN_PLAN" ?
                <>
                <div className="col-6 mt-2">
                  <b className="textStudent text-resize4">ໄຟລຮູບ ໃບນຳສົ່ງ</b>
                </div>
                <div className="col-6 mt-2">
                  <span>
                    {user?.deliveryCardUrl && user?.deliveryCardUrl?.url?.split('.').pop() === "pdf" ?
                      <a rel="noreferrer" target="_blank" href={URL_IMAGE + user?.deliveryCardUrl?.url}> ເປີດໄຟລ PDF</a>
                      : user?.deliveryCardUrl?.url ?
                        <div style={{ display: "flex" }}>
                          <img 
                              alt="" 
                              onClick={() => {
                                setImgShow(true)
                                setImgUrl(URL_IMAGE + user?.deliveryCardUrl?.url)
                              }} 
                              src={URL_IMAGE + user?.deliveryCardUrl?.url} width="40" height="40" />
                        </div> : <div>
                          <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                        </div>
                    }
                  </span>
                </div>
                </>
                : <></>
              }
            </div>
          </div>
        </div>
      </div>
      {/**
       * 
       * show image
       * 
       */}
      <Modal
        show={imgShow}
        onHide={() => setImgShow(false)}
        centered
        size="xl"
      >
        <Modal.Body>
          <img alt="" src={imgUrl} width="100%" height="100%" />
        </Modal.Body>
      </Modal>
    </div>
  );
}
