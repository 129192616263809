import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useReactRouter from "use-react-router";
import { URL_IMAGE_MEDIUM, URL_IMAGE_SMALL, URL_IMAGE } from "../../consts/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import {
  onConvertScholarshipEn,
  onConvertEducationLevelEn,
  onConvertGenderEn,
  convertGenderEn
} from "../../common/super";
import Loading from "../../common/Loading"
import "./index.css";

export default function RegistrationStudentInfoEn() {
  const { history, location } = useReactRouter();
  const user = location?.state;

  const _handleClickUpdateStudent = () => {
    history.push(`/registrationStudentEn`, {code:user?.code, studentType: user?.studentType});
  };

  if(!user) return (<Loading />)
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6" style={{marginTop: 30, float: "left"}} >
          <a 
            style={{color: "#057CAE"}} 
            onClick={() => history.push(`/cardstudentEn/${user?.code}`, user?.code)}
          >
            Student Information
          </a> / {user?.firstNameE ?? "-"}
        </div>
        <div className="2 col-md-6">
          <span style={{ float: "right" }}>
            <Button
              disabled={user?.statusStep == "REJECTED" ? false : true}
              style={{
                marginTop: 30,
                backgroundColor: user?.statusStep == "REJECTED" ? "#057CAE" : "#F5F5F5",
                color: user?.statusStep == "REJECTED" ? "#FFFFFF" : "#D8D8D8",
                border: user?.statusStep == "REJECTED" ? "2px solid #057CAE" : "2px solid #D8D8D8",
              }}
              onClick={_handleClickUpdateStudent}
            >
              <FontAwesomeIcon icon={faUserEdit} /> Edit
            </Button>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="card card-detial-student col-xs-12 col-sm-12 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">Student Information</p>
            <img
              style={{ margin: "0 auto" }}
              src={
                "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
              }
              className="browserImage"
            />
            <div className="row">
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Registration Number</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.studentNumber ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Faculty</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.faculty?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Major</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.department?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Student's information</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.studentInfo == "EMPLOYEE"
                    ? "Employed"
                    : "Normal Student"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Level</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user && user
                    ? onConvertEducationLevelEn(user?.educationLevel)
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">General Information</p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">Given name</b>
              </div>
              <div className="col-6">
                <span>{onConvertGenderEn(user?.gender)} {user?.firstNameE ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Family name</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.lastNameE ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Date of birth</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthday ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Gender</b>
              </div>
              <div className="col-6 mt-2">
                <span>{convertGenderEn(user?.gender) ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Marital status</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.maritualStatus !== "SINGLE" ? "Married" : "Single"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Nationality</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.nationality ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Religion</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.religion ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Home phone</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneHome ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Telephone number</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneMobile ?? "-"}</span>
              </div>
              {/* <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.dormitory === "YES"
                    ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                    : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">Current resident</p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">Country</b>
              </div>
              <div className="col-6">
                <span>{user?.address?.country ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Province</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.province ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">District</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.district ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Village</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.village ?? "-"}</span>
              </div>
              
              <p className="text-header text-resize5 mt-3">Birth place</p>
              <div className="col-6">
                <b className="textStudent text-resize4">Country</b>
              </div>
              <div className="col-6">
                <span>{user?.birthAddress?.country ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Province</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.province ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">District</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.district ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Village</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthAddress?.village ?? "-"}</span>
              </div>

              <p className="text-header text-resize5 mt-3">Emergency contact</p>
              <div className="col-6">
                <b className="textStudent text-resize4">Name and surname</b>
              </div>
              <div className="col-6">
                <span>{user?.contactName ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Telephone number</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.emergencyPhone ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Living together?</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.stayTogether === true ? "Yes" : "No"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            {user && user?.studentType !== "OUT_PLAN_EN" ? (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  For student/Civil servant who receives scholarship from an organization
                </p>
                <div className="row">
                  <div className="col-6">
                    <b className="textStudent text-resize4">Scholarship</b>
                  </div>
                  <div className="col-6">
                    <span>{onConvertScholarshipEn(user?.scholarship) ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Country</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromCountry ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Province</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Graduate from</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Graduate year</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Scholarship Agreement No</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.scholarshipAgreementNo ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Transfer letter No</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.transferLetterNo ?? "-"}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  For self-financing student
                </p>
                <div className="row">
                  <div className="col-6">
                    <b className="textStudent text-resize4">Country</b>
                  </div>
                  <div className="col-6">
                    <span>{user?.graduateFromCountry ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Province</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Graduate from</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">Graduate year</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            <p
              style={{
                fontSize: 20,
                color: "#057CAE",
                fontWeight: "bold",
              }}
            >
              Attach files
            </p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">National ID</b>
              </div>
              <div className="col-6">
                <span>
                  {user?.passportUrl && user?.passportUrl?.url?.split('.').pop() === "pdf" ?
                    <a target="_blank" href={URL_IMAGE + user?.passportUrl?.url}> Open file PDF</a>
                    : user?.passportUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <a target="_blank" href={URL_IMAGE_MEDIUM + user?.passportUrl?.url}>
                          <img src={URL_IMAGE + user?.passportUrl?.url} width="40" height="40" />
                        </a>
                      </div> : <div>
                        <span> Not upload file</span>
                      </div>
                  }
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Graduate certificate</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.qualificationUrl && user?.qualificationUrl?.url?.split('.').pop() === "pdf" ?
                    <a target="_blank" href={URL_IMAGE + user?.qualificationUrl?.url}> Open file PDF</a>
                    : user?.qualificationUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <a target="_blank" href={URL_IMAGE_MEDIUM + user?.qualificationUrl?.url}>
                          <img src={URL_IMAGE + user?.qualificationUrl?.url} width="40" height="40" />
                        </a>
                      </div> : <div>
                        <span> Not upload file</span>
                      </div>
                  }
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">Other</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.otherUrl && user?.otherUrl?.url?.split('.').pop() === "pdf" ?
                    <a target="_blank" href={URL_IMAGE + user?.otherUrl?.url}> Open file PDF</a>
                    : user?.otherUrl?.url ?
                      <div style={{ display: "flex" }}>
                        <a target="_blank" href={URL_IMAGE_MEDIUM + user?.otherUrl?.url}>
                          <img src={URL_IMAGE + user?.otherUrl?.url} width="40" height="40" />
                        </a>
                      </div> : <div>
                        <span> Not upload file</span>
                      </div>
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
