import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Button, Modal, ProgressBar } from "react-bootstrap";
import RegistrationConfirm from "./RegistrationConfirm";
import useReactRouter from "use-react-router";
import CustomButton from "../../common/CustomButton";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { v4 as uuidv4 } from 'uuid';
import {
  USER_KEY,
  PRIMARY_COLOR
} from "../../consts/index";
import { formatDateYYMMDD } from "../../common/super";
import Loading from "../../common/Loading"
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import {
  SLMS_FACULTIES,
  SLMS_MAJORS,
  CREATE_STUDENT,
  UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { PRE_SIGNED_URL } from "../../apollo/registrationstudent";
import { LAO_ADDRESS } from "../../consts/Address";
import { STUDENTS, TRIBES } from "../../apollo/students";

import _ from "lodash";

export default function RegistrationStudent() {
  let NOW = new Date().getFullYear();
  let assessmentYearNow = NOW + " - " + (NOW + 1);
  const { history } = useReactRouter();
  const [getCode, setGetCode] = useState();
  const {studentType, code} = history.location.state
  const [student, setStudent] = useState({});
  const [phoneHome, setPhoneHome] = useState('')
  const [phoneMobile, setPhoneMobile] = useState('')
  const [parentTelephone, setParentTelephone] = useState('')
  const [emergencyPhone, setEmergencyPhone] = useState('')
  const [fileUploadProgress1, setFileUploadProgress1] = useState(0)
  const [fileUploadProgress2, setFileUploadProgress2] = useState(0)
  const [fileUploadProgress3, setFileUploadProgress3] = useState(0)
  const [fileUploadProgress4, setFileUploadProgress4] = useState(0)
  const [fileUploadProgress5, setFileUploadProgress5] = useState(0)
  /* <---------- useState faculty ----------> */
  const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1);

  /* <-------- useState Department ---------> */
  const [majorName, setMajorName] = useState();

  /* <-------- useState District ---------> */
  const [selectProvinceIndex, setSelectProvinceIndex] = useState();
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState();
  const [selectParentProvinceIndex, setSelectParentProvinceIndex] = useState();

  /* <--------  file upload ---------> */
  const [fileUpload, setFileUpload] = useState(null);
  const [idCardOrFamilyBookUrl, setIdCardOrFamilyBookUrl] = useState(null);
  const [idCardOrFamilyBook, setIdCardOrFamilyBook] = useState(null);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [scorecardUrl, setScorecardUrl] = useState(null)
  const [scorecard, setScorecard] = useState(null)
  const [testCardUrl, setTestCardUrl] = useState(null);
  const [testCard, setTestCard] = useState(null);
  const [deliveryCardUrl, setDeliveryCardUrl] = useState(null);
  const [deliveryCard, setDeliveryCard] = useState(null);

  /* <--------  general State ---------> */
  const [graduatedYear, setGraduatedYear] = useState([]);
  const [formParam, setFormParam] = useState({});
  const [show, setShow] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const [click, setClick] = useState("");
  const [type, setType] = useState("");
  const [errorInput, setErrorInput] = useState({});
  const [renderCode, setRenderCode] = useState();
  const [dataLocalStorages, setDataLocalStorages] = useState({});
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
  const [stayTogether, setStayTogether] = useState(false);

  /* <--------- apollo -------> */
  const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL);
  const [loadFaculties, { data: facultyApollo }] = useLazyQuery(SLMS_FACULTIES);
  const [createStudent] = useMutation(CREATE_STUDENT);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [loadDataMajor, { data: dataMajor }] = useLazyQuery(
    SLMS_MAJORS
  );

  const _onLogout = () => {
    if (student?.statusStep !== "REJECTED") {
      history.push(`/`);
      localStorage.removeItem(USER_KEY);
    } else {
      history.goBack();
    }
  };
  const { data, loading } = useQuery(STUDENTS, {
    variables: {
      where: { code: renderCode, assessmentYear: assessmentYearNow },
      orderBy: "createdAt_DESC",
    },
  });
  useEffect(() => {
    setRenderCode(code);
    setGetCode(getUserDataFromLCStorage()?.code);
    setDataLocalStorages(getUserDataFromLCStorage());
  }, []);
  const majorPricesData = dataMajor?.slmsMajors;
  const { data: dataTribe } = useQuery(TRIBES);

  useEffect(() => {
    loadFaculties();
    setStudent(data?.students?.data[0]);
    const _renderCode = _.split(getCode, "/");
    setRenderCode(_renderCode[0]);
    setStayTogether(student?.stayTogether)
  }, [data, student]);

  useEffect(() => {
    if(!_.isEmpty(student)) {
      setIdCardOrFamilyBook(student?.idCardOrFamilyBookUrl?.url);
      setIdCardOrFamilyBookUrl(student?.idCardOrFamilyBookUrl?.url);
      setCertificate(student?.certificateUrl?.url);
      setCertificateUrl(student?.certificateUrl?.url);
      setScorecard(student?.scorecardUrl?.url)
      setScorecardUrl(student?.scorecardUrl?.url)
      setTestCard(student?.testCardUrl?.url);
      setTestCardUrl(student?.testCardUrl?.url);
      setDeliveryCard(student?.deliveryCardUrl?.url)
      setDeliveryCardUrl(student?.deliveryCardUrl?.url)
      setPhoneHome(student?.phoneHome)
      setPhoneMobile(student?.phoneMobile)
      setParentTelephone(student?.parentTelephone)
      setEmergencyPhone(student?.emergencyPhone)
      setStayTogether(student?.stayTogether)
      _setIsSave();
    }
  }, [student]);

  useEffect(async () => {
    if (student?.faculty?.id) {
      _selectFacultyEffect(
        student?.faculty?.id || dataLocalStorages?.faculty?.id
      );
    }
    if (dataLocalStorages?.faculty?.id) {
      await loadDataMajor({
        variables: {
          where: {
            faculty: dataLocalStorages?.faculty?.id,
          },
        },
      });
    }
  }, [student?.faculty?.id, student?.department?.id, dataLocalStorages]);

  // select faculty
  const _selectFacalty = (e) => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: e.target.value,
    });

    if (e?.target?.value) {
      loadDataMajor({
        variables: {
          where: {
            faculty: e.target.value,
          },
        },
      });
    }
    setSelectFacultyIndex(facultyIndex);
  };

  const _selectFacultyEffect = async (id) => {
    loadDataMajor({
      variables: {
        where: {
          faculty: id,
        },
      },
    });
  };

  // select major
  const _selectMajor = (e) => {
    const majorIndex = _.findIndex(majorPricesData, {
      id: e.target.value,
    });
    setMajorName(majorPricesData[majorIndex]?.name);
  };

  // select province
  const _selectProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectProvinceIndex(_provinceIndex);
  };

  const _selectBirthProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
  };

  const _selectParentProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectParentProvinceIndex(_provinceIndex);
  };

  // select District
  const _selectDistrict = (e) => {
    const _districts = LAO_ADDRESS[selectProvinceIndex].district_list.filter(
      (_district) => _district.district === e.target.value
    );
  };
  // useEffect province from database
  useEffect(() => {
    const facultyIndex = _.findIndex(facultyApollo?.slmsFaculties, {
      id: student?.faculty?.id || dataLocalStorages?.faculty?.id,
    });
    setSelectFacultyIndex(facultyIndex);
    setMajorName(dataLocalStorages?.major?.name);
  }, [student, data, student, facultyApollo]);

  useEffect(() => {
    // setPhoneMobile(student?.phoneMobile ?? dataLocalStorages?.phoneMobile)
    // setPhoneHome(student?.phoneHome ?? dataLocalStorages?.phoneHome)
    // setEmergencyPhone(student?.emergencyPhone ?? dataLocalStorages?.emergencyPhone)
    const _provinceBirthIndex = _.findIndex(LAO_ADDRESS, {
      province_name:
        student?.birthAddress?.province ||
        dataLocalStorages?.birthAddress?.province,
    });
    setSelectBirthProvinceIndex(_provinceBirthIndex);

    const _provinceParentIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.parentAddress?.province,
    });
    setSelectParentProvinceIndex(_provinceParentIndex);
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.address?.province || dataLocalStorages?.address?.province,
    });
    setSelectProvinceIndex(_provinceIndex);
  }, [student, dataLocalStorages]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 1; i >= 1995; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setGraduatedYear(year);
  }, []);

  useEffect(() => {
    _setIsSave();
  }, [idCardOrFamilyBookUrl, testCardUrl, certificateUrl, scorecardUrl, deliveryCardUrl]);

  useEffect(() => {
    if (dataPreSign?.preSignedUrl) {
      fileGetResign(dataPreSign?.preSignedUrl);
    } else {
      setIsLoading(false)
    }
  }, [dataPreSign]);

  const fileGetResign = async (data) => {
    try {
      await axios({
        method: "put",
        url: data.url,
        data: fileUpload,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        /* ສະແດງເປີເຊັນຂໍ້ມູນທີ່ອັບໂຫລດແລ້ວ  */
        onUploadProgress: function (progressEvent) {
          let percentComplete = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          if(click === "1") {
            setFileUploadProgress1(percentComplete)
          }
          if(click === "2") {
            setFileUploadProgress2(percentComplete)
          }
          if(click === "3") {
            setFileUploadProgress3(percentComplete)
          }
          if(click === "4") {
            setFileUploadProgress4(percentComplete)
          }
          if(click === "5") {
            setFileUploadProgress5(percentComplete)
          }
          if(click === "1" && percentComplete === 100) {
            setIdCardOrFamilyBookUrl(data?.url.split("?")[0]);
            setFileUploadProgress1(0)
          }
          if(click === "2" && percentComplete === 100) {
            setCertificateUrl(data?.url.split("?")[0]);
            setFileUploadProgress2(0)
          }
          if(click === "3" && percentComplete === 100) {
            setScorecardUrl(data?.url.split("?")[0]);
            setFileUploadProgress3(0)
          }
          if(click === "4" && percentComplete === 100) {
            setTestCardUrl(data?.url.split("?")[0]);
            setFileUploadProgress4(0)
          }
          if(click === "5" && percentComplete === 100) {
            setDeliveryCardUrl(data?.url.split("?")[0]);
            setFileUploadProgress5(0)
          }
        }
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  // ເລືອກໄຟລຈາກ ຄອມ
  const _handleChangeFile = async (acceptedFiles) => {
    try {
      // ໄປຂໍ url ຈາກ s3
      setIsLoading(true)
      let data = uuidv4() + '.' + acceptedFiles?.target?.files[0].type.split('/')[1];
      switch (click) {
        case "1":
          setIdCardOrFamilyBook(data);
          break;
        case "2":
          setCertificate(data);
          break;
        case "3":
          setScorecard(data);
          break;
        case "4":
          setTestCard(data);
          break;
        case "5":
          setDeliveryCard(data);
          break;
      }
      setFileUpload(acceptedFiles?.target?.files[0]);
      loadPreSign({
        variables: {
          name: data,
        },
      });
    } catch (error) {
      setIsLoading(false)
    }
  };
  var arr = [];
  const _setIsSave = () => {
    if(studentType === "IN_PLAN") {
      if (idCardOrFamilyBookUrl && testCardUrl && certificateUrl && scorecardUrl && deliveryCardUrl) {
        setIsSave(false);
      } else {
        setIsSave(true);
      }
    }else {
      if (idCardOrFamilyBookUrl && testCardUrl && certificateUrl && scorecardUrl) {
        setIsSave(false);
      } else {
        setIsSave(true);
      }
    }
  };

  const _clickStayTogether = (e) => {
    setStayTogether(!stayTogether);
  };
  const handlePhoneHomeChange = evt => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

    if (rx_live.test(evt.target.value)) {
      setPhoneHome(evt.target.value)
    }
  }
  const handlePhoneMobileChange = evt => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

    if (rx_live.test(evt.target.value)) {
      setPhoneMobile(evt.target.value)
    }
  }
  const handleParentTelephoneChange = evt => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

    if (rx_live.test(evt.target.value)) {
      setParentTelephone(evt.target.value)
    }
  }
  const handleEmergencyPhoneChange = evt => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/

    if (rx_live.test(evt.target.value)) {
      setEmergencyPhone(evt.target.value)
    }
  }
  const handleSubmit2 = async (event, values) => {
    event.preventDefault();
    var errors = {};
    if (studentType == "IN_PLAN") {
      if (values.email == "") {
        delete values.email;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }
      delete values.outPlanGraduateFromProvince;
      delete values.outPlanGraduateFromSchool;
      delete values.outPlanSemesterYear;

      if(!values.scholarship) {
        errors.scholarship = "Please select scholarship"
      }
      if(!values.graduateFromProvince) {
        errors.graduateFromProvince = "Please input graduate province/state"
      }
      if(!values.graduateFromSchool) {
        errors.graduateFromSchool = "Please input graduate school"
      }
      if(!values.semesterYear) {
        errors.semesterYear = "Please input academic year"
      }
    }
    delete values.examCode;
    if (values.email == "") {
      delete values.email;
    }
    if (values.lastNameL == "") {
      delete values.lastNameL;
    }
    delete values.explainNotToStayTogether;
    delete values.otherScholarship;
    delete values.stayTogether;
    delete values.acceptTerms;
    if (studentType == "OUT_PLAN") {
      if (values.email == "") {
        delete values.email;
      }
      if (values.lastNameL == "") {
        delete values.lastNameL;
      }
      delete values.graduateFromProvince;
      delete values.graduateFromSchool;
      delete values.scholarship;
      if (values.scholarshipAgreementNo == "") {
        delete values.scholarshipAgreementNo;
      }
      delete values.semesterYear;
      if (values.transferLetterNo === "") {
        delete values.transferLetterNo;
      }

      if(!values.outPlanGraduateFromProvince) {
        errors.outPlanGraduateFromProvince = "Please input graduate province/state"
      }
      if(!values.outPlanGraduateFromSchool) {
        errors.outPlanGraduateFromSchool = "Please input graduate school"
      }
      if(!values.outPlanSemesterYear) {
        errors.outPlanSemesterYear = "Please input academic year"
      }
    }

    if(!values.studentInfo) {
      errors.studentInfo = "Please select student's information"
    }
    if(!values.educationLevel) {
      errors.educationLevel = "Please select level"
    }
    if(!values.firstNameE) {
      errors.firstNameE = "Please input given name"
    }
    if(!values.lastNameE) {
      errors.lastNameE = "Please input family name"
    }
    if(!values.nationality) {
      errors.nationality = "Please select nationality"
    }
    if(!values.tribe) {
      errors.tribe = "Please select tribe"
    }
    if(!values.religion) {
      errors.religion = "Please select religion"
    }
    // if(!phoneHome) {
    //   errors.phoneHome = "Please select phoneHome"
    // }
    if(!phoneMobile) {
      errors.phoneMobile = "Please input your mobile phone"
    }
    if(!values.address) {
      errors.address = "Please input current province/state"
    }
    if(!values.district) {
      errors.district = "Please input current district"
    }
    if(!values.village) {
      errors.village = "Please input current village"
    }
    if(!values.birthAddress) {
      errors.birthAddress = "Please input birth province/state"
    }
    if(!values.birthDistrict) {
      errors.birthDistrict = "Please input birth district"
    }
    if(!values.birthVillage) {
      errors.birthVillage = "Please input birth village"
    }
    if(!values.fatherNameAndSurname) {
      errors.fatherNameAndSurname = "Please input father name and surname"
    }
    if(!values.motherNameAndSurname) {
      errors.motherNameAndSurname = "Please input mother name and surname"
    }
    if(!parentTelephone) {
      errors.parentTelephone = "Please input parents mobile phone"
    }
    if(!values.parentProvince) {
      errors.parentProvince = "Please input parent province/state"
    }
    if(!values.parentDistrict) {
      errors.parentDistrict = "Please input parents district"
    }
    if(!values.parentVillage) {
      errors.parentVillage = "Please input parent village"
    }
    if(!values.contactName) {
      errors.contactName = "Please input contact name"
    }
    if(!emergencyPhone) {
      errors.emergencyPhone = "Please input emergency phone"
    }
    setErrorInput(errors);
    console.log(errors)
    let address = {
      province: values.address,
      district: values.district,
      village: values.village,
    };
    values = {
      ...values,
      address: address,
    };
    delete values.village;
    delete values.district;

    let birthAddress = {
      province: values.birthAddress,
      district: values.birthDistrict,
      village: values.birthVillage,
    };
    values = {
      ...values,
      birthAddress: birthAddress,
    };
    delete values.birthVillage;
    delete values.birthDistrict;

    let parentAddress = {
      province: values.parentProvince,
      district: values.parentDistrict,
      village: values.parentVillage,
    };
    values = {
      ...values,
      parentAddress: parentAddress,
    };
    delete values.parentProvince;
    delete values.parentVillage;
    delete values.parentDistrict;
    let data = {
      ...values,
      yearLevel: "1",
      examCode: dataLocalStorages?.id,
      faculty: facultyApollo?.slmsFaculties[selectFacultyIndex]?.id,
      department: values.department,
      major: values.major,
      facultyname:
        facultyApollo?.slmsFaculties[selectFacultyIndex]?.name,
      majorName: majorName,
      tribe: values.tribe,
      idCardOrFamilyBookUrl: { url: idCardOrFamilyBook },
      certificateUrl: { url: certificate },
      scorecardUrl: { url: scorecard },
      deliveryCardUrl: { url: deliveryCard },
      testCardUrl: { url: testCard },
      phoneHome: phoneHome,
      phoneMobile: phoneMobile,
      parentTelephone: parentTelephone,
      emergencyPhone: emergencyPhone,
      studentType: studentType,
      stayTogether: stayTogether,
      statusStep: "SEND_FORM",
      statusStepUpdate: student?.statusStep,
      graduateFromProvince: values.outPlanGraduateFromProvince
        ? values.outPlanGraduateFromProvince
        : values.graduateFromProvince,
      graduateFromSchool: values.outPlanGraduateFromSchool
        ? values.outPlanGraduateFromSchool
        : values.graduateFromSchool,
      semesterYear: values.outPlanSemesterYear
        ? values.outPlanSemesterYear
        : values.semesterYear,
      id: student?.id,
    };
    delete data.outPlanGraduateFromProvince;
    delete data.outPlanGraduateFromSchool;
    delete data.outPlanSemesterYear;
    delete data.birthDistrict;
    delete data.birthVillage;
    delete data.district;
    delete data.village;
    if (student) {
      data.id = student.id;
    }
    let paramQL = {
      data,
    };
    setFormParam(paramQL);
    const checkData = _.isEmpty(errors)
    console.log('>>>', errors)
    if(!checkData) {
      setShowDanger(true)
      setTimeout(() => {
        setShowDanger(false)
      }, 2500);
      return
    }
    if (type == "save") {
      try {
        if (student) {
          delete data.facultyname;
          delete data.majorName;
          delete data.statusStepUpdate;
          delete data.statusStep;
          const updateData = await updateStudent({
            variables: {
              where: { id: student?.id },
              data: data,
            },
          });
          if(updateData) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
        if (!student) {
          delete data.facultyname;
          delete data.majorName;
          delete data.statusStep;
          const createNewStudent = await createStudent({ variables: paramQL });
          if(createNewStudent) {
            setShow(true);
            setTimeout(() => {
              setShow(false);
              window.location.reload();
            }, 1000);
          }
        }
      } catch (err) { }
    } else if (type == "verify") {
      _handleShowAddConfirmModalShow();
    }
  }

  if(loading) return (<Loading />)
  return (
    <div className="container mt-5">
      <div className="col-sm-12 card p-4" style={{ color: "#707070" }}>
        {/* {studentData && */}
        {
          <Formik
            enableReinitialize={true}
            initialValues={{
              examCode: dataLocalStorages?.id ?? "",
              faculty: student?.faculty?.id ?? dataLocalStorages?.faculty?.id,
              department: student?.department?.id ?? dataLocalStorages?.department?.id,
              major: student?.major?.id ?? dataLocalStorages?.major?.id,
              code: renderCode,
              examNumber: getCode,
              studentInfo: student?.studentInfo ?? "",
              educationLevel: student?.educationLevel ?? "BACHELOR_DEGREE",
              firstNameL: student?.firstNameL ?? dataLocalStorages?.firstNameL,
              lastNameL: student?.lastNameL ?? dataLocalStorages?.lastNameL,
              firstNameE: student?.firstNameE ?? dataLocalStorages?.firstNameE,
              lastNameE: student?.lastNameE ?? dataLocalStorages?.lastNameE,
              birthday:
                student?.birthday ??
                dataLocalStorages?.birthday ??
                "1990-01-01",
              dateTransferLetterNo:
                formatDateYYMMDD(student?.dateTransferLetterNo) ?? "",
              dateScholarshipAgreementNo:
                formatDateYYMMDD(student?.dateScholarshipAgreementNo) ?? "",
              gender: student?.gender ?? dataLocalStorages?.gender ?? "MALE",
              maritualStatus: student?.maritualStatus ?? dataLocalStorages?.maritualStatus ?? "SINGLE",
              nationality: student?.nationality ?? dataLocalStorages?.nationality ?? "",
              tribe: student?.tribe?.id ?? dataLocalStorages?.tribe?.id ?? "",
              religion: student?.religion ?? dataLocalStorages?.religion ?? "",
              // phoneHome: student?.phoneHome ?? dataLocalStorages?.phoneHome,
              // phoneMobile:
              //   student?.phoneMobile ?? dataLocalStorages?.phoneMobile,
              email: student?.email ?? dataLocalStorages?.email ?? "",
              address: student?.address?.province ?? dataLocalStorages?.address?.province ?? "",
              district: student?.address?.district ?? dataLocalStorages?.address?.district ?? "",
              village: student?.address?.village ?? dataLocalStorages?.address?.village ?? "",
              birthAddress:
                student?.birthAddress?.province ??
                dataLocalStorages?.birthAddress?.province,
              birthDistrict:
                student?.birthAddress?.district ??
                dataLocalStorages?.birthAddress?.district,
              birthVillage:
                student?.birthAddress?.village ??
                dataLocalStorages?.birthAddress?.village,
              parentProvince: student?.parentAddress?.province ?? "",
              parentDistrict: student?.parentAddress?.district ?? "",
              parentVillage: student?.parentAddress?.village ?? "",
              scholarship: student?.scholarship ?? "",
              otherScholarship: student?.otherScholarship ?? "",
              graduateFromProvince: student?.graduateFromProvince ?? dataLocalStorages?.graduateFromProvince ?? "",
              graduateFromSchool: student?.graduateFromSchool ?? dataLocalStorages?.graduateFromSchool ?? "",
              semesterYear: student?.semesterYear ?? dataLocalStorages?.semesterYear ?? "",
              outPlanGraduateFromProvince: student?.graduateFromProvince ?? dataLocalStorages?.graduateFromProvince ?? "",
              outPlanGraduateFromSchool: student?.graduateFromSchool ?? dataLocalStorages?.graduateFromSchool ?? "",
              outPlanSemesterYear: student?.semesterYear ?? dataLocalStorages?.semesterYear ?? "",
              contactName:
                student?.contactName ?? dataLocalStorages?.contactName,
              // emergencyPhone:
              //   student?.emergencyPhone ?? dataLocalStorages?.emergencyPhone,
              stayTogether: student?.stayTogether ?? false,
              acceptTerms: student?.acceptTerms ?? false,
              explainNotToStayTogether: student?.explainNotToStayTogether ?? "",
              dormitory: student?.dormitory ?? "YES",
              fatherNameAndSurname: student?.fatherNameAndSurname ?? "",
              motherNameAndSurname: student?.motherNameAndSurname ?? "",
              // parentTelephone: student?.parentTelephone ?? "",
              transferLetterNo: student?.transferLetterNo ?? "",
              scholarshipAgreementNo: student?.scholarshipAgreementNo ?? "",
            }}
            validate={(values) => {
              
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={(event) => handleSubmit2(event, values)}>
                <div className="card_registrationStudents">
                  <div className="card-body card_registerstrationStudents_body">
                    <span className="float-right textfont-size18">
                      <a
                        href="https://cu-bucket-files.s3.ap-southeast-1.amazonaws.com/files/%E0%BA%84%E0%BA%B9%E0%BB%88%E0%BA%99%E0%BA%B3%E0%BB%83%E0%BA%8A%E0%BB%89-%E0%BA%A5%E0%BA%BB%E0%BA%87%E0%BA%97%E0%BA%B0%E0%BA%9A%E0%BA%BD%E0%BA%99%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99+%E0%BA%A1%E0%BA%88+2023-2024+new+update.pdf"
                        target="_blank"
                      >
                        <u>ຄູ່ມືແນະນຳ</u>
                      </a>
                    </span>
                    <h6 className="mt-3 textForm">
                      ແບບ​ຟອມ​ຂໍ້​ມູນ​ລົງ​ທະ​ບຽນ
                    </h6>
                    <div className="card bg-register">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</div>

                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ລະຫັດສອບເສັງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="ກະ​ລຸ​ນາ​ລະຫັດສອບເສັງ"
                          name="examNumber"
                          value={values.examNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຄະ​ນ​ະ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="faculty"
                          isInvalid={errors.faculty}
                          value={values.faculty}
                          onChange={(e) => {
                            handleChange(e);
                            _selectFacalty(e);
                          }}
                          disabled
                        >
                          <option disabled={false} value="">
                            ---ເລືອກ---
                          </option>
                          {facultyApollo?.slmsFaculties.map((x, index) => (
                            <option key={"faculty" + index} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ສາ​ຂາ​ວິ​ຊາ
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="hidden"
                          name="department"
                          isInvalid={errors.department}
                          value={values.department}
                          onChange={handleChange}
                          disabled
                        />
                        <Form.Control
                          as="select"
                          name="major"
                          isInvalid={errors.major}
                          value={values.major}
                          onChange={(e) => {
                            handleChange(e);
                            _selectMajor(e);
                          }}
                          disabled
                        >
                          <option value="">---ເລືອກ---</option>
                          {majorPricesData &&
                            majorPricesData?.map((x, index) => (
                              <option key={"major" + index} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold" name="">
                          ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="studentInfo"
                          value={values.studentInfo}
                          isInvalid={errorInput && errorInput?.studentInfo}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="EMPLOYEE">ເປັນພະນັກງານມາຮຽນຕໍ່</option>
                          <option value="ORDINARY_STUDENT">
                            ເປັນນັກຮຽນສາມັນມາຮຽນຕໍ່
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ລະ​ດັບ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.educationLevel}
                          name="educationLevel"
                          isInvalid={errorInput && errorInput?.educationLevel}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກ---</option>
                          {/* <option value="DEPLOMA">ອະນຸປະລິນຍາ</option> */}
                          <option value="BACHELOR_DEGREE">ປະລິນຍາຕີ</option>
                          {/* <option value="BACHELOR_CONTINUING">
                            ປະລິນຍາຕີຕໍ່ເນື່ອງ
                          </option> */}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="card bg-register">ຂໍ້ມູນທົ່ວໄປ</div>
                    <div className="row margin-resgintrationStudent">
                    <div className="col-xs-12 col-md-2 mt-ຟ">
                        <label className="text-bold">
                          ເລືອກເພດ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-ຟ">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ທ້າວ"
                            type="radio"
                            value="MALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "MALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-ຟ">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ນາງ"
                            type="radio"
                            value="FEMALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "FEMALE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-ຟ">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ພຣະ"
                            type="radio"
                            value="MONK"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "MONK" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-ຟ">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ສໍາມະເນນ"
                            type="radio"
                            value="NOVICE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            checked={values.gender == "NOVICE" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.firstNameL}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່​ແທ້​ຂອງ​ທ່ານ"
                          name="firstNameL"
                          maxLength="20"
                          disabled={dataLocalStorages?.firstNameL ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.firstNameL}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ນາມສະກຸນ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.lastNameL}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                          name="lastNameL"
                          maxLength="20"
                          disabled={dataLocalStorages?.lastNameL ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.lastNameL}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ (ພາສາອັງກິດ) <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.firstNameE}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນຊື່​ແທ້​ເປັນ​ພາ​ສາ​ອັງ​ກິດ"
                          name="firstNameE"
                          maxLength="20"
                          disabled={dataLocalStorages?.firstNameE ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.firstNameE}
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ນາມສະກຸນ (ພາສາອັງກິດ){" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.lastNameE}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                          name="lastNameE"
                          maxLength="20"
                          disabled={dataLocalStorages?.lastNameE ? true : false}
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.lastNameE}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ວັນ​ເດືອນ​ປີ​ເກີດ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={values.birthday}
                          placeholder="12/02/2000"
                          name="birthday"
                          onChange={handleChange}
                          isInvalid={errors.birthday}
                          disabled
                        />
                      </div>
                      <div className="col-md-6 mt-3" />
                      <div className="row p-0 m-0">
                        <div className="col-md-4 mt-3">
                          <Form.Label fontSize="14" className="text-bold">
                            ແຂວງເກີດ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="birthAddress"
                            onChange={(e) => {
                              _selectBirthProvince(e);
                              handleChange(e);
                            }}
                            isInvalid={errorInput && errorInput?.birthAddress}
                            value={values.birthAddress}
                          // disabled
                          >
                            <option value="">---ເລືອກແຂວງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option
                                key={"province" + index}
                                value={province?.province_name}
                              >
                                {province?.province_name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                        <div className="col-md-4 mt-3">
                          <Form.Label fontSize="14" className="text-bold">
                            ເມືອງເກີດ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={values.birthDistrict}
                            name="birthDistrict"
                            isInvalid={errorInput && errorInput?.birthDistrict}
                            onChange={handleChange}
                          >
                            <option value="">---ເລືອກເມືອງ---</option>
                            {selectBirthProvinceIndex > -1 &&
                              LAO_ADDRESS[
                                selectBirthProvinceIndex
                              ].district_list.map((district, index) => (
                                <option
                                  key={"birth-district" + index}
                                  value={district.district}
                                >
                                  {district.district}
                                </option>
                              ))}
                          </Form.Control>
                        </div>
                        <div className="col-md-4 mt-3">
                          <Form.Label fontSize="14" className="text-bold">
                            ບ້ານເກີດ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="birthVillage"
                            value={values.birthVillage}
                            isInvalid={errorInput && errorInput?.birthVillage}
                            placeholder="ກະລຸນາປ້ອນບ້ານ"
                            onChange={handleChange}
                            maxLength="30"
                          // disabled
                          />
                        </div>
                        <div className="col-md-4 mt-3" />
                      </div>
                      <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          ສະຖານະ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ໂສດ"
                            type="radio"
                            value="SINGLE"
                            id="maritualStatus-radio"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "SINGLE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ແຕ່ງງານແລ້ວ"
                            type="radio"
                            id="maritualStatus-radio"
                            value="MARRIAGE"
                            name="maritualStatus"
                            onChange={handleChange}
                            checked={
                              values.maritualStatus == "MARRIAGE" ? true : false
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3" />
                      {/* <div className="col-xs-12 col-md-2 mt-3">
                        <label className="text-bold">
                          ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-xs-6 col-md-2 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                            type="radio"
                            value="YES"
                            id="dormitory-radio"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory === "YES" ? true : false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xs-12 col-md-6 mt-3">
                        <Form.Group>
                          <Form.Check
                            inline
                            label="ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                            type="radio"
                            id="dormitory-radio"
                            value="NO"
                            name="dormitory"
                            onChange={handleChange}
                            checked={values.dormitory === "NO" ? true : false}
                          />
                        </Form.Group>
                      </div> */}
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ສັນຊາດ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.nationality}
                          name="nationality"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.nationality}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="ລາວ">ລາວ</option>
                          <option value="ໄທ">ໄທ</option>
                          <option value="ຈີນ">ຈີນ</option>
                          <option value="ຫວຽດ">ຫວຽດ</option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊົນເຜົ່າ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.tribe}
                          name="tribe"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.tribe}
                        >
                          <option value="">---ເລືອກ---</option>
                          {dataTribe &&
                            dataTribe?.tribes.map((item, index) => {
                              return (
                                <option value={item.id}>{item?.name}</option>
                              );
                            })}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ສາສະໜາ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.religion}
                          name="religion"
                          onChange={handleChange}
                          isInvalid={errorInput && errorInput?.religion}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="ພຸດ">​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                          <option value="ຜີ">ສາ​ສະ​ໜາຜີ</option>
                          <option value="ຄຣິສະ​ຕຽນ">ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ</option>
                          <option value="ສາ​ສະ​ໜາອິດສະລາມ">
                            ສາ​ສະ​ໜາອິດສະລາມ
                          </option>
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທລະສັບມືຖື <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          pattern='[+-]?\d+(?:[.,]\d+)?'
                          value={dataLocalStorages?.phoneMobile ?? phoneMobile}
                          maxLength="11"
                          placeholder="ກະລຸນາ ປ້ອນເບີໂທລະສັບມືຖື"
                          name="phoneMobile"
                          isInvalid={errorInput && errorInput?.phoneMobile}
                          onChange={handlePhoneMobileChange}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ອີເມວ
                        </Form.Label>
                        <Form.Control
                          type="email"
                          value={values.email}
                          placeholder="ກະລຸນາປ້ອນອີເມວ"
                          name="email"
                          maxLength="30"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທເຮືອນ
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          pattern='[+-]?\d+(?:[.,]\d+)?'
                          value={phoneHome}
                          maxLength="11"
                          placeholder="ກະລຸນາ ປ້ອນເບີໂທເຮືອນ"
                          name="phoneHome"
                          // isInvalid={errorInput && errorInput?.phoneHome}
                          onChange={handlePhoneHomeChange}
                          // onChange={handleParentTelephoneChange}
                        />
                      </div>
                    </div>
                    <div className="card bg-register">ທີ່ຢູ່ປະຈຸບັນ</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ແຂວງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="address"
                          onChange={(e) => {
                            _selectProvince(e);
                            handleChange(e);
                          }}
                          value={values.address}
                          isInvalid={errorInput && errorInput?.address}
                          id="disabledSelect"
                          placeholder="ປະລິນຍາຕີ"
                        >
                          <option value="">---ເລືອກແຂວງ---</option>
                          {LAO_ADDRESS.map((province, index) => (
                            <option
                              key={"province" + index}
                              value={province?.province_name}
                            >
                              {province?.province_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເມືອງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.district}
                          isInvalid={errorInput && errorInput?.district}
                          name="district"
                          onChange={(e) => {
                            _selectDistrict(e);
                            handleChange(e);
                          }}
                          // value={values.district}
                        >
                          <option value="">---ເລືອກເມືອງ---</option>
                          {selectProvinceIndex > -1 &&
                            LAO_ADDRESS[selectProvinceIndex].district_list.map(
                              (district, index) => (
                                <option key={"district" + index}>
                                  {district.district}
                                </option>
                              )
                            )}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ບ້ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="village"
                          isInvalid={errorInput && errorInput?.village}
                          placeholder="ກະລຸນາປ້ອນບ້ານ"
                          maxLength="30"
                          onChange={handleChange}
                          value={values.village}
                        />
                      </div>
                    </div>
                    {studentType !== "IN_PLAN" ? (
                      <div>
                        <div className="card bg-register">
                          ສໍາລັບນັກສືກສາລະບົບຈ່າຍຄ່າຮຽນ
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບຈາກແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanGraduateFromProvince"
                              isInvalid={errorInput && errorInput?.outPlanGraduateFromProvince}
                              onChange={handleChange}
                              value={values.outPlanGraduateFromProvince}
                            >
                              <option value="">---ເລືອກ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province?.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບຈາກໂຮງຮຽນ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              value={values.outPlanGraduateFromSchool}
                              name="outPlanGraduateFromSchool"
                              isInvalid={errorInput && errorInput?.outPlanGraduateFromSchool}
                              onChange={handleChange}
                              maxLength="40"
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ສົກສືກສາ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="outPlanSemesterYear"
                              isInvalid={errorInput && errorInput?.outPlanSemesterYear}
                              onChange={handleChange}
                              value={values.outPlanSemesterYear}
                            >
                              <option>---ເລືອກສົກສືກສາ---</option>
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="card bg-register">
                          ສ​ຳລັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານ ທຶນ​ແບ່ງ​ປັນ
                        </div>
                        <div className="row margin-resgintrationStudent">
                          <div className="col-md-12 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ທຶນແບ່ງປັນ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              id="disabledSelect"
                              name="scholarship"
                              value={values.scholarship}
                              isInvalid={errorInput && errorInput?.scholarship}
                              onChange={handleChange}
                            >
                              <option value="">---ເລືອກທຶນ---</option>
                              <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                                ໃນແຜນຂອງ​ແຂວງ
                              </option>
                              <option value="NUOL_SCHOLARSHIP">
                                ໃນແຜນ ມຈ
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                ໃນແຜນຊົນເຜົ່າ
                              </option>
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບ​ຈາກແຂວງ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="graduateFromProvince"
                              value={values.graduateFromProvince}
                              isInvalid={errorInput && errorInput?.graduateFromProvince}
                              onChange={handleChange}
                            >
                              <option value="">---ເລືອກເມືອງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ຈົບ​ຈາກ​ໂຮງ​ຮຽນ{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              value={values.graduateFromSchool}
                              name="graduateFromSchool"
                              isInvalid={errorInput && errorInput?.graduateFromSchool}
                              onChange={handleChange}
                              maxLength="40"
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ສົກ​ສຶກ​ສາ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="semesterYear"
                              isInvalid={errorInput && errorInput?.semesterYear}
                              onChange={handleChange}
                              value={values.semesterYear}
                            >
                              <option value="">---ເລືອກສົກສືກສາ---</option>
                              {graduatedYear.map((x, index) => {
                                return (
                                  <option value={x} key={index}>
                                    {x}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເລກທີໃບນຳສົ່ງ
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="transferLetterNo"
                              placeholder="ກະ​ລຸ​ນາ​ ປ້ອນເລກທີໃບນຳສົ່ງ"
                              onChange={handleChange}
                              value={values.transferLetterNo}
                              maxLength="20"
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ລົງ​ວັນ​ທີ່
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={values.dateTransferLetterNo}
                              name="dateTransferLetterNo"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຈ (ປ້ອນສະເພາະເສັງໄດ້ທຶນ
                              ມຈ)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມຈ"
                              name="scholarshipAgreementNo"
                              value={values.scholarshipAgreementNo}
                              onChange={handleChange}
                              maxLength="20"
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <Form.Label fontSize="14" className="text-bold">
                              ລົງ​ວັນ​ທີ່
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="dateScholarshipAgreementNo"
                              onChange={handleChange}
                              value={values.dateScholarshipAgreementNo}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="card bg-register">ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ ແລະ ນາມສະກຸນພໍ່{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນພໍ່"
                          name="fatherNameAndSurname"
                          isInvalid={errorInput && errorInput?.fatherNameAndSurname}
                          onChange={handleChange}
                          value={values.fatherNameAndSurname}
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ຊື່ ແລະ ນາມສະກຸນແມ່{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນແມ່"
                          name="motherNameAndSurname"
                          isInvalid={errorInput && errorInput?.motherNameAndSurname}
                          onChange={handleChange}
                          value={values.motherNameAndSurname}
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີໂທລະສັບພໍ່/ແມ່ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນເບີໂທລະສັບພໍ່/ແມ່"
                          name="parentTelephone"
                          isInvalid={errorInput && errorInput?.parentTelephone}
                          onChange={handleParentTelephoneChange}
                          value={parentTelephone}
                          maxLength="11"
                        />
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ແຂວງປະຈຸບັນ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="parentProvince"
                          onChange={(e) => {
                            _selectParentProvince(e);
                            handleChange(e);
                          }}
                          isInvalid={errorInput && errorInput?.parentProvince}
                          value={values.parentProvince}
                        >
                          <option value="">---ເລືອກແຂວງ---</option>
                          {LAO_ADDRESS.map((province, index) => (
                            <option
                              key={"province" + index}
                              value={province?.province_name}
                            >
                              {province?.province_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເມືອງປະຈຸບັນ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.parentDistrict}
                          name="parentDistrict"
                          isInvalid={errorInput && errorInput?.parentDistrict}
                          onChange={handleChange}
                        >
                          <option value="">---ເລືອກເມືອງ---</option>
                          {selectParentProvinceIndex > -1 &&
                            LAO_ADDRESS[
                              selectParentProvinceIndex
                            ].district_list.map((district, index) => (
                              <option
                                key={"parent-district" + index}
                                value={district.district}
                              >
                                {district.district}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ບ້ານປະຈຸບັນ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="parentVillage"
                          value={values.parentVillage}
                          isInvalid={errorInput && errorInput?.parentVillage}
                          placeholder="ກະລຸນາປ້ອນບ້ານ"
                          onChange={handleChange}
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="card bg-register">ກໍລະນີສຸກເສີນຕິດຕໍ່</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ພົວ​ພັນ​ທ່ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.contactName}
                          name="contactName"
                          isInvalid={errorInput && errorInput?.contactName}
                          placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່ ແລະ ນາມ​ສະ​ກຸນ"
                          onChange={handleChange}
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ເບີ​ໂທ​ຕິດ​ຕໍ່ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          value={dataLocalStorages?.emergencyPhone ?? emergencyPhone}
                          maxLength="15"
                          name="emergencyPhone"
                          isInvalid={errorInput && errorInput?.emergencyPhone}
                          placeholder="​ກະ​ລຸ​ນາ ປ້ອນ​ເບີ​ຕິດ​ຕໍ່"
                          onChange={handleEmergencyPhoneChange}
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <Form.Group
                          controlId="formBasicCheckbox"
                          style={{ marginTop: 25 }}
                        >
                          <Form.Check
                            type="checkbox"
                            label="ຢູ່ຮ່ວມກັນ"
                            name="stayTogether"
                            value={values.stayTogether}
                            onChange={handleChange}
                            onClick={() => _clickStayTogether()}
                            maxLength="100"
                            defaultChecked={stayTogether}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 mt-3">
                        <Form.Label fontSize="14" className="text-bold">
                          ໝາຍເຫດ ຫາກບໍ່ໄດ້ຢູ່ຮ່ວມກັນ ໃຫ້ລະບຸດ້ານລຸ່ມ
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.explainNotToStayTogether}
                          isInvalid={errors.explainNotToStayTogether}
                          name="explainNotToStayTogether"
                          placeholder="​ກະ​ລຸ​ນາ​ປ້ອນ​ສະ​ຖານ​ທີ່"
                          onChange={handleChange}
                          disabled={stayTogether == true ? true : false}
                        />
                      </div>
                    </div>
                    <div className="card bg-register">ອັບໂຫຼດເອກະສານຄັດຕິດ</div>
                    <div style={{ marginTop: 10 }}>ສາມາດອັບໂຫຼດຟາຍຮູບໄດ້ສະເພາະນາມສະກຸນ: .png, .heif, .jpeg, .jpg, .pdf ເທົ່ານັ້ນ</div>
                    <div className="row margin-resgintrationStudent">
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ (ພຣະ: ໜັງສືສຸທິ){" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput && errorInput?.idCardOrFamilyBookUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload"
                            onChange={_handleChangeFile}
                            hidden
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onClick={() => setClick("1")}
                          />
                          <label
                            htmlFor="file-upload"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "1" && fileUploadProgress1 > 0 && fileUploadProgress1 < 100 ? 
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar animated now={fileUploadProgress1} label={`${fileUploadProgress1}%`} />
                                </div>
                                :
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              }
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {idCardOrFamilyBookUrl && fileUploadProgress1 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {idCardOrFamilyBook}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setIdCardOrFamilyBook(null);
                                    setIdCardOrFamilyBookUrl(null);
                                  }}
                                  variant={"danger"}
                                  className="mb-3"
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ໃບປະກາດ{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput && errorInput?.certificateUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload2"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("2")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload2"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "2" && fileUploadProgress2 > 0 && fileUploadProgress2 < 100 ? 
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar animated now={fileUploadProgress2} label={`${fileUploadProgress2}%`} />
                                </div>
                                :
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              }
                              {/* {click == 2 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />} */}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {certificateUrl && fileUploadProgress2 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {certificate}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setCertificate(null);
                                    setCertificateUrl(null);
                                  }}
                                  variant={"danger"}
                                  className="mb-3"
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ໃບຄະແນນ{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput && errorInput?.scorecardUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload3"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("3")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload3"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "3" && fileUploadProgress3 > 0 && fileUploadProgress3 < 100 ? 
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar animated now={fileUploadProgress3} label={`${fileUploadProgress3}%`} />
                                </div>
                                :
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              }
                              {/* {click == 2 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />} */}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {scorecardUrl && fileUploadProgress3 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {scorecard}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setScorecard(null);
                                    setScorecardUrl(null);
                                  }}
                                  variant={"danger"}
                                  className="mb-3"
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ບັດເຂົ້າຫ້ອງເສັງ{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput && errorInput?.testCardUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload4"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("4")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload4"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "4" && fileUploadProgress4 > 0 && fileUploadProgress4 < 100 ? 
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar animated now={fileUploadProgress4} label={`${fileUploadProgress4}%`} />
                                </div>
                                :
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              }
                              {/* {click == 3 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />} */}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {testCardUrl && fileUploadProgress4 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {testCard}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTestCard(null);
                                    setTestCardUrl(null);
                                  }}
                                  variant={"danger"}
                                  className="mb-3"
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      {studentType === "IN_PLAN" ?
                      <>
                      <div className="col-md-6 mt-3">
                        ອັບໂຫຼດໄຟລຮູບ ໃບນຳສົ່ງ{" "}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="col-sm-12 center"
                          style={{
                            border: errorInput && errorInput?.deliveryCardUrl ? "1px solid red" : "1px solid #ddd",
                            outline: "none",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="file-upload5"
                            accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                            onChange={_handleChangeFile}
                            onClick={() => setClick("5")}
                            hidden
                          />
                          <label
                            htmlFor="file-upload5"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#f3f3f3",
                                marginLeft: 20,
                                marginTop: 5,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              {click === "5" && fileUploadProgress5 > 0 && fileUploadProgress5 < 100 ? 
                                <div>
                                  <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                                  <ProgressBar animated now={fileUploadProgress5} label={`${fileUploadProgress5}%`} />
                                </div>
                                :
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                              }
                              {/* {click == 3 && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size="2x" />} */}
                            </div>
                          </label>
                          <span>ເລືອກໄຟລ</span>
                          {deliveryCardUrl && fileUploadProgress5 === 0 && (
                            <aside>
                              <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                              {/* <ul>{_fileUploaded}</ul> */}
                              {deliveryCard}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setDeliveryCard(null);
                                    setDeliveryCardUrl(null);
                                  }}
                                  variant={"danger"}
                                  className="mb-3"
                                >
                                  ລົບໄຟລ
                                </Button>
                              </div>
                            </aside>
                          )}
                        </div>
                      </div>
                      </>
                      : <></>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="d-flex justify-content-center fixed-bottom button-submit-registerStudent"
                      style={{
                        backgroundColor: "#fff",
                        padding: 10,
                      }}
                    >
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                          className="buttonoutline"
                          onClick={() => setShowConfirmLogout(true)}
                        >
                          ຍົກເລີກ
                        </Button>
                      </div>
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          type="submit"
                          className="btn btn-success"
                          onClick={() => {
                            setType("save");
                            // handleSubmit()
                          }}
                          style={{ backgroundColor: "#1B8900", color: "#fff" }}
                          disabled={isSave == true ? false : (isLoading ? true : false)}
                        >
                          ບັນທຶກ
                        </Button>
                      </div>
                      <div className="col-xs-6 col-md-4 text-center marign-button-apply-right">
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={() => {
                            setType("verify");
                            // handleSubmit();
                          }}
                          disabled={isSave == true ? true : (isLoading ? true : false)}
                        >
                          ສົ່ງຂໍ້ມູນ <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        }
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {isSaving ? <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          </Col> : <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size72 text-bold"
              style={{ color: "#00A1DE" }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ບັນທຶກຂໍ້ມູນຮຽບຮ້ອຍ
            </div>
          </Col>}
        </Modal.Body>
      </Modal>

      <Modal show={showDanger} onHide={() => setShowDanger(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div className="textfont-size40 text-bold" style={{ color: "red" }}>
              ປ້ອນຂໍ້ມູນບໍ່ຄົບ!
            </div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="5x"
              style={{ color: "red" }}
            />
            <div
              className="textfont-size18 text-bold"
              style={{ marginTop: 40 }}
            >
              ກະລຸນາ ກວດຄືນແຕ່ລະບ໋ອກ!
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ marginTop: 50 }}
        show={showConfirmLogout}
        onHide={() => setShowConfirmLogout(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: PRIMARY_COLOR, fontWeight: "bold" }}>
              ອອກຈາກລະບົບ
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              // className="text-resize5"
              style={{ fontWeight: "bold", fontSize: 24, color: "#6f6f6f" }}
            >
              ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ?
            </div>
          </Col>
          <Col className="d-flex justify-content-between">
            <div />
            <CustomButton
              onClick={() => setShowConfirmLogout(false)}
              type="submit"
              title="ຍົກເລີກ"
            />

            <CustomButton
              confirm
              onClick={() => _onLogout()}
              type="submit"
              title="ຢືນຢັນ"
            />
            <div />
          </Col>
        </Modal.Body>
      </Modal>
      <RegistrationConfirm
        showAddConfirmModal={showAddConfirmModal}
        studentType={studentType}
        param={formParam}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
      />
    </div>
  );
}
