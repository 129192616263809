import { gql } from "apollo-boost";

export const FACULTIES = gql`
  query Faculties($where: FacultyInput) {
    faculties(where: $where){
        id
        name
        departments{
          id
          name
      }
    }
  }
`;
export const SLMS_FACULTIES = gql`
  query SlmsFaculties($where: SlmsFacultyInput) {
    slmsFaculties(where: $where){
        id
        name
    }
  }
`;

export const SLMS_DEPARTMENTS = gql`
  query SlmsDepartments($where: SlmsDepartmentInput) {
    slmsDepartments(where: $where){
      id
      name
    }
  }
`;
export const SLMS_MAJORS = gql`
  query SlmsMajors($where: SlmsMajorInput) {
    slmsMajors(where: $where){
      id
      name

    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation CreateStudent($data: StudentInput!) {
    createStudent(data: $data){
      id
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($data: StudentInput!,$where: studentWhereInputId!) {
    updateStudent(data: $data,where:$where){
      id
    }
  }
`;
export const UPDATE_STUDENT_STATUS = gql`
  mutation UpdateStudentStatus($data: StudentStatusInput!,$where: StudentStatusWhereInput!) {
    updateStudentStatus(data: $data,where:$where){
      id
    }
  }
`;

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($name: String) {
    preSignedUrl(name: $name) {
      url
    }
  }
`