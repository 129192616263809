import React from "react";
import moment from "moment";
// import Notiflix from "notiflix-react";


// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
    let resp = moment(dashDate).format("DD/MM/YYYY");
    return resp;
};
export const formatDateYYMMDD = (dashDate) => {
    let resp = moment(dashDate).format("YYYY-MM-DD");
    return resp;
};
export const formatTime = (dashDate) => {
    let resp = moment(dashDate).format("HH:mm:ss");
    return resp;
};

export const formatDate = (dateTime) => {
    moment.locale("lo");
    let resp = moment(dateTime).format("DD MMMM YYYY");
    if (dateTime) return resp;
    else return "";
  };

export const onConvertGender = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ທ້າວ";
        break;
      case "FEMALE":
        result = "ນາງ";
        break;
      case "MONK":
        result = "ພຣະ";
        break;
      case "NOVICE":
        result = "ສຳມະເນນ";
        break;
    }
    return result;
  };
export const onConvertGenderEn = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "Mr";
        break;
      case "FEMALE":
        result = "Ms";
        break;
      case "MONK":
        result = "Monk";
        break;
      case "NOVICE":
        result = "Novice";
        break;
    }
    return result;
  };

 export const onConvertMaritualStatus = (maritualStatus) => {
    let result;
    switch (maritualStatus) {
      case "SINGLE":
        result = "ໂສດ";
        break;
      case "MARRIAGE":
        result = "ແຕ່ງງານແລ້ວ";
        break;
      default:
        result = "ໂສດ";
    }
    return result;
  };
 export const onConvertMaritualStatusEn = (maritualStatus) => {
    let result;
    switch (maritualStatus) {
      case "SINGLE":
        result = "Single";
        break;
      case "MARRIAGE":
        result = "Married";
        break;
      default:
        result = "Single";
    }
    return result;
  };

 export const onConvertScholarship = (scholarship) => {
    let result;
    switch (scholarship) {
      case "ANTIONAL_STUDENT_SCHOLARSHIP":
        result = "ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ";
        break;
      case "NUOL_SCHOLARSHIP":
        result = "ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ";
        break;
      case "RED_DIPLOMA_SCHOLARSHIP":
        result = "ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ";
        break;
      case "NO_ONE_STUDENT_OF_PROVINCIAL":
        result = "ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ";
        break;
      case "ETHNIC_STUDENT_OF_PROVINCIAL":
        result = "ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ";
        break;
      case "OFFICIAL_STAFF_SCHOLARSHIP":
        result = "ພະ​ນັກ​ງານ";
        break;
      case "OTHER_SHOLARSHIP":
        result = "ທຶນ​ແບ່ງ​ປັນ​ອື່ນໆ";
        break;
    }
    return result;
  };

  export const onConvertScholarshipEn = (scholarship) => {
    let result
    switch (scholarship) {
      case 'ORGANIZATION_IN_LAOPDR_MINISTRIES':
        result = 'The organization in the Lao PDR(Ministries)'
        break
      case 'ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION':
        result = 'The organization in the Lao PDR(Provincial Education Division)'
        break
      case 'HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD':
        result = 'Higher Education institution form abroad'
        break
      case 'PERSONAL_FINANCIAL':
        result = 'Personal financial'
        break
      case 'ANTIONAL_STUDENT_SCHOLARSHIP':
        result = 'Outstanding student in general subject/talent student'
        break
      case 'NUOL_SCHOLARSHIP':
        result = "NUOL's talent student"
        break
      case 'RED_DIPLOMA_SCHOLARSHIP':
        result = 'First-class honours'
        break
      case 'ETHNIC_STUDENT_OF_PROVINCIAL':
        result = 'Provincial ethnic student'
        break
      case 'MINISTRY_OF_EDUCATION_AND_SPORTS':
        result = 'Ministry of Education and Sports'
        break
      case 'OTHER_SHOLARSHIP':
        result = 'Other scholarship'
        break
    }
    return result
  }

 export const onConvertStudentInfo = (studentInfo) => {
    let result;
    switch (studentInfo) {
      case "EMPLOYEE":
        result = "ເປັນພະນັກງານມາຮຽນຕໍ່";
        break;
      case "ORDINARY_STUDENT":
        result = "ເປັນນັກຮຽນສາມັນມາຮຽນຕໍ່";
        break;
    }
    return result;
  };

 export const onConvertEducationLevelEn = (educationLevel) => {
    let result;
    switch (educationLevel) {
      case "DEPLOMA":
        result = "Associate Degree";
        break;
      case "BACHELOR_DEGREE":
        result = "Bachelor Degree";
        break;
      case "BACHELOR_CONTINUING":
        result = "Continuing Bachelor Degree";
        break;
    }
    return result;
  };
 export const onConvertEducationLevel = (educationLevel) => {
    let result;
    switch (educationLevel) {
      case "DEPLOMA":
        result = "ອະນຸປະລິນຍາ";
        break;
      case "BACHELOR_DEGREE":
        result = "ປະລິນຍາຕີ";
        break;
      case "BACHELOR_CONTINUING":
        result = "ປະລິນຍາຕີຕໍ່ເນື່ອງ";
        break;
    }
    return result;
  };

  export const convertGender = (gender) => {
    let result = "";
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "MONK":
        result = "ພຣະ";
        break;
      case "NOVICE":
        result = "ສຳມະເນນ";
        break;
    }
    return result;
  };
  export const convertGenderEn = (gender) => {
    let result = "";
    switch (gender) {
      case "MALE":
        result = "Male";
        break;
      case "FEMALE":
        result = "Female";
        break;
      case "MONK":
        result = "Monk";
        break;
      case "NOVICE":
        result = "Novice";
        break;
    }
    return result;
  };