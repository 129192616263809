import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../images/morjor_logo.png'
import { Formik } from 'formik'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { BrowserView, MobileView } from 'react-device-detect'
import useReactRouter from 'use-react-router'
import { USER_DATA_KEY, USER_KEY } from '../../consts/index'
import { LOGIN } from '../../apollo/studentAuth'
import pngkit_call from '../../images/pngkit_call.png'
import { Button, Form, Row, Col, Modal } from 'react-bootstrap'
import Loading from '../../common/Loading'
import {
  faChevronLeft,
  faLock,
  faSignInAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { STUDENTS } from '../../apollo/students'
import _, { set } from 'lodash'

function Form_login_en () {
  const { history, location, match } = useReactRouter()
  let NOW = new Date().getFullYear();
  let assessmentYearNow = NOW + " - " + (NOW + 1);
  // const studentTypeForm = match.params.studentType
  const [studentTypeForm, setStudentTypeForm] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorStudentType, setErrorStudentType] = useState(false)
  const [showDanger, setShowDanger] = useState(false)
  const [showModalMobile, setShowModalMobile] = useState(false)
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [login, {loading}] = useMutation(LOGIN)
  const [loadDataStudent, { data: dataStudent }] = useLazyQuery(STUDENTS, {fetchPolicy: "no-cache"})

  useEffect(() => {
    const fetch = async () => {
      if (dataStudent?.students) {
        if (dataStudent?.students?.data?.length > 0) {
          if(studentTypeForm === "OUT_PLAN" || studentTypeForm === "IN_PLAN") {
            setIsLoading(false)
            setErrorStudentType(true)
          }else {
            await localStorage.setItem(
              USER_DATA_KEY,
              JSON.stringify(dataStudent?.students?.data[0]?.studentType)
            )
            if (dataStudent?.students?.data[0]?.statusStep == 'SAVED') {
              history.push('/registrationStudentEn', {studentType:dataStudent?.students?.data[0]?.studentType})
              setIsLoading(false)
            } else {
              history.push(
                `/cardstudentEn/${dataStudent?.students?.data[0]?.code}`
              )
              setIsLoading(false)
            }
          }
        } else {
          if(studentTypeForm === "OUT_PLAN" || studentTypeForm === "IN_PLAN") {
            setIsLoading(false)
            setErrorStudentType(true)
          }else {
            history.push('/registrationStudentEn', {studentType:studentTypeForm})
            setIsLoading(false)
          }
        }
      }
    }
    fetch()
  }, [dataStudent])
  
  const _login = async (values) => {
    try {
      setIsLoading(true)
      const codeInput = _.split(code, '/')
      const responseData = await login({
        variables: {
          data: {
            code: code
          }
        }
      })
      const data = responseData?.data?.login
      setStudentTypeForm(responseData?.data?.login?.studentType)
      await localStorage.setItem(USER_KEY, JSON.stringify(data))
      await loadDataStudent({
        variables: {
          where: { code: codeInput[0], assessmentYear: assessmentYearNow }
        }
      })
    } catch (error) {
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }

  const _onEnterKey = async (e) => {
  	if (e.key == 'Enter') {
      e.preventDefault()
      try {
        setIsLoading(true)
        const codeInput = _.split(code, '/')
        const responseData = await login({
          variables: {
            data: {
              code: code
            }
          }
        })
        const data = responseData?.data?.login
        setStudentTypeForm(responseData?.data?.login?.studentType)
        await localStorage.setItem(USER_KEY, JSON.stringify(data))
        await loadDataStudent({
          variables: {
            where: { code: codeInput[0], assessmentYear: assessmentYearNow }
          }
        })
      } catch (error) {
        setIsLoading(false)
        setErrorMessage(error.message)
      }
  	}
  }

  const _handleChangeCode = async (e) => {
    setCode(e.target.value);
  }
  if(isLoading) return <Loading/>
  return (
    <div style={{ background: '#F5F5F5', height: '100vh' }}>
      <BrowserView>
      <div
        className='textfont-size20'
        style={{ paddingRight: 24, paddingTop: 24, position: 'fixed', right: 0 }}
      >
        <a style={{textDecorationLine: 'underline', color:'#0000EE'}} onClick={() => setShowDanger(true)}>
          Need help?
        </a>
      </div>
      <div className='textfont-size24 text-center' style={{ marginBottom: 41 }}>
        <img className='logo' src={logo} />
        <br />
        National University of Laos
      </div>
      <div className='d-flex justify-content-center'>
            <div
              style={{
                backgroundColor: '#fff',
                padding: 30,
                paddingLeft: 80,
                paddingRight: 80,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h2>
                <b>Login to your account</b>
              </h2>

              <Form
                noValidate
                style={{
                  width: '100%',
                  paddingTop: 24,
                  justifyContent: 'center',
                }}
              >
                <Form.Group as={Row} controlId='formPlaintextEmail'>
                  <Form.Label
                    column
                    sm='2'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginTop: -5
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} size='2x' color='#057CAE' />
                  </Form.Label>
                  <Col sm='8' style={{ marginBottom: 27 }}>
                    <Form.Control
                      name='code'
                      type='text'
                      placeholder='Enter your registration number'
                      onChange={(e) => _handleChangeCode(e)}
                      onKeyDown={(e) => _onEnterKey(e)}
                      value={code}
                    />
                    {errorMessage ? (
                      <span style={{ color: '#CC0000', fontSize: 14 }}>
                        {errorMessage == 'GraphQL error: THE_CODE_CAN_NOT_FOUND'
                          ? '* Incorrect code. Please try again'
                          : ''}
                        {errorMessage == 'LOGIN_IS_NOT_FORMATTED'
                          ? '* ລະ​ຫັດທີ່ທ່ານບໍ່ຕົງກັບແບບຟອມນີ້, ກະລຸນາກັບຄືນເພື່ອເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ ທີຖືກຕ້ອງ'
                          : ''}
                      </span>
                    ) : (
                      ''
                    )}
                    {errorStudentType ? (
                      <span style={{ color: '#CC0000', fontSize: 14 }}>
                        <br />* This code does not work for this form.<br />
                        Please login another form.
                      </span>
                    ) : <></>
                    }
                  </Col>
                  <Col sm='2'></Col>
                  <Col sm='1'></Col>
                  <Col sm='9'>
                    <Button
                      style={{
                        width: '97%',
                        backgroundColor: '#057CAE',
                        marginTop: 24,
                        marginLeft: 15
                      }}
                      type="button"
                      onClick={() => _login()}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            fontSize: 20
                          }}
                        >
                          Login
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: 30,
                            alignItems: 'flex-end'
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSignInAlt}
                            style={{ fontSize: 24, fontWeight: 'normal' }}
                            color='#fff'
                          />
                        </div>
                      </div>
                    </Button>
                  </Col>
                </Form.Group>
                <div
                    className='textfont-size18 bottomleft'
                    style={{ marginTop: 45 }}
                  >
                    “Note: Please login using your registration number”
                    <br/>Example: 124N331/21
                  </div>
              </Form> 
            </div>
      </div>
      </BrowserView>

      <MobileView>
      <div className='d-flex justify-content-center'>
        <div style={{textAlign: "center"}}>
          <img width="99px" src={logo} />
        </div>
      </div>
      <div style={{ marginBottom: 41, marginTop: 10, fontSize: 12, textAlign: "center" }}>
        ມະຫາວິທະຍາໄລ ຈຳປາສັກ<br/>
        Champasack University

      </div>
      <div style={{display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#F5F5F5"}}>
        <Formik
          initialValues={{
            code: ''
          }}
          onSubmit={async values => {
            _login(values)
          }}
        >
          {({ values, handleSubmit, handleChange }) => (
            <div
              style={{
                width: 300,
                backgroundColor: '#fff',
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
                <b>Login to your account</b>

              <Form
                noValidate
                style={{
                  justifyContent: 'center',
                  marginTop: 12
                }}
              >
                <Form.Group controlId='formPlaintextEmail'>
                  <div style={{display: "flex", flexDirection: "row", }}>
                  <div style={{ marginTop: 40, marginRight: 10 }}>
                    <FontAwesomeIcon icon={faUser} size='2x' color='#057CAE' />
                  </div>
                  <div style={{ marginTop: 40 }}>
                    <Form.Control
                    style={{fontSize: 14}}
                    name='code'
                    type='text'
                    placeholder='Enter your registration number'
                    onChange={(e) => _handleChangeCode(e)}
                    onKeyDown={(e) => _onEnterKey(e)}
                    value={code}
                    />
                    {errorMessage ? (
                      <span style={{ color: '#CC0000', fontSize: 10 }}>
                        {errorMessage == 'GraphQL error: THE_CODE_CAN_NOT_FOUND'
                          ? '* Incorrect registration number. Please try again'
                          : ''}
                        {errorMessage == 'LOGIN_IS_NOT_FORMATTED'
                          ? '* ລະ​ຫັດທີ່ທ່ານບໍ່ຕົງກັບແບບຟອມນີ້, ກະລຸນາກັບຄືນເພື່ອເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ ທີຖືກຕ້ອງ'
                          : ''}
                      </span>
                    ) : (
                      ''
                    )}
                    {errorStudentType ? (
                      <span style={{ color: '#CC0000', fontSize: 14 }}>
                        <br />* This code does not work for this form.<br />
                        Please login another form.
                      </span>
                    ) : <></>
                    }
                  </div>
                  </div>
                  
                  <div>
                    <Button
                      style={{
                        width: '100%',
                        backgroundColor: '#057CAE',
                        marginTop: 45,
                        height: 40
                      }}
                      onClick={() => handleSubmit()}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            fontSize: 12
                          }}
                        >
                          Login
                        </div>
                        <div >
                          <FontAwesomeIcon
                            icon={faSignInAlt}
                            style={{ fontSize: 24, fontWeight: 'normal' }}
                            color='#fff'
                          />
                        </div>
                      </div>
                    </Button>
                  </div>
                </Form.Group>
              </Form>

              <div style={{ marginTop: 34, fontSize: 12 }} >
                “Note: Please login using your registration number”
                <br/>Example: 124N331/21
              </div>
            </div>
          )}
        </Formik>
        <div style={{ fontSize: 14, textAlign: "center", marginTop: 75, }}>
          ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{' '}
          <a href='#' onClick={() => setShowModalMobile(true)}>
            Help
          </a>
        </div>
      </div>
      <Modal
        show={showModalMobile}
        onHide={() => setShowModalMobile(false)}
        animation={false}
      >
        <Modal.Header className='borderModalLoginMobile'>
          <div>Contact us</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: '#707070',
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 'bold'
          }}
        >
          <p>Tel: 021 771 989</p>
          <p>ວິຊາການ ມຈ: 020 5445 6359, 020 5252 2266</p>
          <p>Email: info.01slms@gmail.com</p>
          <p>Note: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
          <img src={pngkit_call} style={{ width: 120, height: 79 }} />
        </Modal.Body>
      </Modal>
      </MobileView>

      <Modal
        show={showDanger}
        onHide={() => setShowDanger(false)}
        animation={false}
      >
        <Modal.Header className='borderModalLogin'>
          <div>Contact us</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: '#707070',
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          <p>Tel:</p>
          <p>021 771 989</p>
          <p>ວິຊາການ ມຈ:</p>
          <p>020 5445 6359</p>
          <p>020 5252 2266</p>
          <p>Email:</p>
          <p>info.01slms@gmail.com</p>
          <p>Note: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
          <img src={pngkit_call} style={{ width: 190, height: 121 }} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Form_login_en
