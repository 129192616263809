import React, { useEffect } from 'react'
import CryptoJS from 'crypto-js'
import { Col, Row, Card } from 'react-bootstrap'
import useReactRouter from 'use-react-router'

import onepay from '../../images/onepay.png'
import bcelcommerce from '../../images/bcel-commerce.png'
import slms from '../../images/slms.png'

export default function TuitionPaymentChannels () {
  const { history } = useReactRouter()
  const dataStudent = history?.location?.state

  const _clickOnepay = () => {
    history.push('/onepay', history?.location?.state)
  }
  const _clickSLMS = () => {
    history.push('/paythroughslms', history?.location?.state)
  }

  useEffect(() => {
    var sid = RandomNumber()
    var access_key = '3b6a754a0e6f3369883ae5d7a08d19a6'
    var profile_id = '317CF8B0-9B3B-4758-AB2F-01FB1CB04FE4'
    var secret_key =
      '0782c358298d407ea68c6c833e40220666f106c4a22a47d985baaae7751930f0fefb40547a4b4c84a80fb119a9708af425988597604b40beb220e4bbbe9a2630c71e311e0cf948ed8f0e5d7c4e252d273fba71aac0cb46afb184b0320d57ce357774e26b3ea64dffaa29f724665ccfe150f0d7f34a1d417ead7068c7d120c8c5'
    // var merchant_id = 'bcel_test_account'

    var params = []
    params['access_key'] = access_key
    params['profile_id'] = profile_id
    params['transaction_uuid'] = uniqid()
    params['signed_date_time'] = gmdate()
    params['locale'] = 'en'
    params['transaction_type'] = 'authorization'
    params['reference_number'] = RandomNumber()
    params['currency'] = 'lak'

    params['device_fingerprint_id'] = sid

    params['amount'] = 100
    params['bill_to_address_country'] = 'LA'
    params['bill_to_forename'] = dataStudent?.firstNameL
    params['bill_to_surname'] = dataStudent?.lastNameL
    params['bill_to_email'] = dataStudent?.mail
    params['bill_to_phone'] = dataStudent?.phoneMobile
    params['bill_to_address_city'] = dataStudent?.address?.province
    params['bill_to_address_line1'] = dataStudent?.address?.village
    params['bill_to_address_postal_code'] = ''
    params['merchant_secure_data1'] = ''
    params['merchant_secure_data2'] = ''
    params['merchant_secure_data3'] = ''

    params['signed_field_names'] = ''
    params['signed_field_names'] = implode(params)

    params['signature'] = signParams(params, secret_key)

    // set preview billing address
    document.getElementById('name').innerHTML =
      params['bill_to_forename'] + ' ' + params['bill_to_surname']
    document.getElementById('address').innerHTML =
      params['bill_to_address_line1'] +
      ', ' +
      params['bill_to_address_city'] +
      ', ' +
      params['bill_to_address_postal_code'] +
      ', ' +
      params['bill_to_address_country']
    document.getElementById('phone').innerHTML = params['bill_to_phone']
    document.getElementById('email').innerHTML = params['bill_to_email']
    document.getElementById('amount').innerHTML = params['amount']
    document.getElementById('ccy').innerHTML = params['currency']

    // set form data
    var form = document.getElementById('form')
    for (let field in params) {
      form.innerHTML +=
        "<input type='hidden' name='" +
        field +
        "' value='" +
        params[field] +
        "'/>"
    }
    form.innerHTML += ''

    function signParams (params, secret_key) {
      var dataToSign = []
      var joinedData = ''
      var signedFieldNames = params['signed_field_names'].split(',')

      signedFieldNames.forEach(function (field) {
        dataToSign.push(field + '=' + params[field])
      })
      joinedData = implode(dataToSign, 'v')

      var hash = CryptoJS.HmacSHA256(joinedData, secret_key)
      var hashInBase64 = CryptoJS.enc.Base64.stringify(hash)
      return hashInBase64
    }

    function implode (params, type) {
      var keys = []
      for (let key in params) {
        if (type === 'v') keys.push(params[key])
        else keys.push(key)
      }
      return keys.join()
    }

    function uniqid (prefix, moreEntropy) {
      if (typeof prefix === 'undefined') prefix = ''

      var retId
      var _formatSeed = function (seed, reqWidth) {
        seed = parseInt(seed, 10).toString(16) // to hex str
        if (reqWidth < seed.length) {
          // so long we split
          return seed.slice(seed.length - reqWidth)
        }
        if (reqWidth > seed.length) {
          // so short we pad
          return Array(1 + (reqWidth - seed.length)).join('0') + seed
        }
        return seed
      }

      var $global = typeof window !== 'undefined' ? window : global
      $global.$locutus = $global.$locutus || {}
      var $locutus = $global.$locutus
      $locutus.php = $locutus.php || {}

      if (!$locutus.php.uniqidSeed) {
        // init seed with big random int
        $locutus.php.uniqidSeed = Math.floor(Math.random() * 0x75bcd15)
      }
      $locutus.php.uniqidSeed++

      // start with prefix, add current milliseconds hex string
      retId = prefix
      retId += _formatSeed(parseInt(new Date().getTime() / 1000, 10), 8)
      // add seed hex string
      retId += _formatSeed($locutus.php.uniqidSeed, 5)
      if (moreEntropy) {
        // for more entropy we add a float lower to 10
        retId += (Math.random() * 10).toFixed(8).toString()
      }

      return retId
    }

    function gmdate () {
      var today = new Date()
      today = today.toISOString()
      let start = today.substring(0, today.indexOf('.') + 0)
      let end = today.substring(today.indexOf('.') + 4)
      return start + end
    }

    function RandomNumber () {
      return Math.floor(100000 + Math.random() * 900000)
    }

    return params
  }, [])

  return (
    <div className='container' style={{ height: '100vh' }}>
      <Card style={{border: "none"}}>
        <Card.Body>
          <Row>
            <Col
              sm={12}
              style={{
                fontSize: 24,
                marginTop: 58,
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: 42
              }}
            >
              ກ​ະ​ລຸ​ນາ, ເລືອກ​ຊ່ອງ​ທາງ​ໃນ​ການ​ຊຳ​ລະ​ຄ່າ​ໃຊ້​ບໍ​ລິ​ການ SLMS
            </Col>
            <Col sm={2}></Col>
            <Col sm='8' style={{ marginBottom: 50 }}>
              <div className='borderled' onClick={() => _clickOnepay()}>
                  <span> <img alt='' className='logo' style={{marginLeft: -7, marginRight: 34}} src={onepay} /> ຊ່ອງ​ທາງ​ທີ່ 1: ​ຊຳ​ລະ​ຜ່ານ Onepay </span> 
              </div>
              <form
                id='form'
                action='https://testsecureacceptance.cybersource.com/oneclick/pay'
                method='post'
              >
                <button type='submit' className='borderled'>
                  <span>
                    <img alt="" className='logo' src={bcelcommerce} /> ຊ່ອງ​ທາງ​ທີ່ 2: ຊຳ​ລະ​​ຜ່ານບັດ Visa, Mastercard, JCB, Amex
                  </span>{' '}
                </button>
              </form>
              <p style={{ display: 'none' }} id='name'></p>
              <p style={{ display: 'none' }} id='address'></p>
              <p style={{ display: 'none' }} id='phone'></p>
              <p style={{ display: 'none' }} id='email'></p>
              <span id='amount' style={{ display: 'none' }}></span>{' '}
              <span id='ccy' style={{ display: 'none' }}></span>
              <div className='borderled' onClick={() => _clickSLMS()}>
                <div>
                  <span><img alt="" className='logo' style={{marginLeft: -1,  marginRight: 34}} src={slms} /> ຊ່ອງ​ທາງ​ທີ່ 3: ຊຳ​ລະ​ຜ່ານ ສູນ SLMS </span> 
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
