import React, { useEffect, useState } from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card } from "react-bootstrap";
import useReactRouter from 'use-react-router';
import { v4 as uuidv4 } from 'uuid';
import { OnePay } from "../../onepay";
import imgOnplay from '../../images/oneplay.jpg';
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import {CREATE_PAYMENT} from '../../apollo/payment';
import {UPDATE_STUDENT,UPDATE_STUDENT_STATUS } from "../../apollo/registrationstudent";
import { DEPARTMENT_PRICE } from '../../apollo/students'




export default function Onepay() {

    const { history, location, match } = useReactRouter();
    const id = history?.location?.state?.id
    const codeId = history?.location?.state?.code
    const departmentId = history?.location?.state?.department?.id
    const [code, setCode] = useState("");
    const [isShowCode, setIsShowCode] = useState(false);
    const [isShowSuccessIcon, setIsShowSuccessIcon] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [mcid, setMcid] = useState("mch5c2f0404102fb");  // merchant id
    const [amount, setAmount] = useState(1);  // invoice amount
    // const [amount, setAmount] = useState();  // invoice amount
    const [description, setDescription] = useState("Test NUOL OnePay by QR Code");  // must define as English text
    const [ccy, setCcy] = useState(418);  // currency LAK
    const [country, setCountry] = useState("LA");
    const [province, setProvince] = useState("VTE");

    const [createPayment, { data: createPaymentData}] = useMutation(CREATE_PAYMENT)
    const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT)
    const [updateStudentStatus, { data: updateDataStudent }] = useMutation(UPDATE_STUDENT_STATUS)
    const [loadDataDepartmentPrice, { data: dataDepartmentPrice }] = useLazyQuery(DEPARTMENT_PRICE, {
        variables:{
          where:{id: departmentId }
        }
      })
    const departmentPrice = dataDepartmentPrice?.departmentWithPrice?.price

    const transactionid = uuidv4(); // transaction id ((please define as unique key))
    const data = {
        mcid,
        ccy,
        country,
        province
    }
    let onePay = new OnePay(data); // create new OnePay instance
    useEffect(() => {
            _getQRCode();
            _subcribeQRCode();
    }, [])
    // useEffect(() => {
    //     loadDataDepartmentPrice()
    // }, [departmentId])

    // useEffect(() => {
    //     setAmount(departmentPrice)
    // }, [dataDepartmentPrice])
    // useEffect(() => {
    //     if(amount){
    //         _getQRCode();
    //     }
    // }, [amount])
    
    /* get OnePay QR code */
    const _getQRCode = () => {
        onePay.getCode({
            transactionid: transactionid,
            amount: amount,
            description: description,
        }, function (_code) {
            if (_code && _code != "") {
                setStatusText("Scan QR Code to payment")
                setIsShowCode(true)
                setIsShowSuccessIcon(false)
                setCode(_code)
            }
        });
    }
   
    /* subscribe to receiving OnePay callback*/
    const _subcribeQRCode = async () => {
        onePay.subscribe(transactionid, async (res) => {
            if (res.uuid === transactionid) {
                 try{
                 const _createPaymentData = await createPayment({variables: {data: {
                    paymentId: res?.id,
                    mcId: res?.mcId,
                    ccy: res?.ccy,
                    merchantName: res?.merchantName,
                    paymentName: res?.name,
                    phone: res?.phone,
                    amount: res?.amount,
                    description: res?.description,
                    
                }}});
                  // console.log("studentData",studentData)
                  updateStudent({
                     variables: {
                         data: {statusStep:  "APPROVED",
                         payment: _createPaymentData?.data?.createPayment?.id
                     },
                         where: { id: id }
                     }
                 })
                 updateStudentStatus({
                     variables: {
                         data: {statusStep:  "APPROVED"
                     },
                         where: { code: codeId }
                     }
                 })
                 setStatusText("The QR Code is Paid")
                 setIsShowSuccessIcon(true);
                 setIsShowCode(false);
                 setTimeout(() => {
                     history.push(`/cardstudent/${codeId}`)
                     window.location.reload()
                 }, 2000);
                 // setIsShowCode(false)
 
            }catch(err){
                alert("ສ້າງ onepay ສຳເລັດ")
            }
              
            }
        });
    }
    const onClick = () => {
        const uri = 'onepay://qr/' + code;
        window.location.href = uri;
    }

    return (
        <div className="container" style={{ height: "100vh" }}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center", flexDirection: "row", marginBottom: 50 }}>
                            <Card>
                                <Card.Body className="cardOneplay">
                                    <Col style={{ fontSize: 29, fontWeight: "bold" }}>
                                        ຊ່ອງ​ທາງ​ການ​ຊຳ​ລະ​ເງິນ​ດ້ວຍ Onepay
                                    </Col>
                                    <Col className="oneplayBorder">
                                        <div><img src={imgOnplay} /></div>
                                        {isShowSuccessIcon && <img src="https://www.freeiconspng.com/uploads/success-icon-10.png"
                                            style={{
                                                width: 200,
                                                height: 200,
                                                marginTop: 35,
                                                marginBottom: 35
                                            }} />}
                                        {isShowCode && <img onClick={() => onClick()} className="qr-code"
                                            src={'https://chart.googleapis.com/chart?chs=343x343&cht=qr&chl=' + code + '&choe=UTF-8'} />}
                                    </Col>
                                    <Col style={{ fontSize: 29, fontWeight: "bold" }}>
                                        ກ​ະ​ລຸ​ນາ, ສະ​ແກນ​ເພື່ອ​ຊຳ​ລ​ະ​ເງິນ
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
