import React, { useEffect, useState } from "react";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Col, Row, Form, Button, Modal, Card } from "react-bootstrap";

import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { STUDENTS } from "../../apollo/students";
import { UPDATE_STUDENT } from "../../apollo/registrationstudent";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { formatDate } from "../../common/super";
import Loading from "../../common/Loading"

export default function CardStudentEn() {
  const { history, match } = useReactRouter();
  const codeId = match?.params?.student;
  const [data, setData] = useState({});
  const [statusSteps, setStatusSteps] = useState(false);
  const [studentStatus, setStudentStatus] = useState("REQUEST")
  // const [statusRQR, setStatusRQR] = useState();
  // const [statusRR, setStatusRR] = useState();
  const [statusRA, setStatusRA] = useState();
  const [clickCheckbox, setClickCheckbox] = useState();
  const [acceptTerms, setAcceptTerms] = useState();
  const [dataStudent, { data: student, loading: loadStudentData }] = useLazyQuery(STUDENTS, {
    variables: {
      where: { code: codeId },
      orderBy: "createdAt_DESC",
    },
  });

  const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT);

  useEffect(async () => {
    await dataStudent();
    setData(student?.students?.data[0]);
    setClickCheckbox(student?.students?.data[0]?.acceptTerms);
    setAcceptTerms(student?.students?.data[0]?.acceptTerms);
  }, [student, data]);

  useEffect(() => {
    if (data?.statusStep !== "REJECTED") {
      setStatusSteps(true);
    }else{
      setStatusSteps(false);
    }
    setStudentStatus(data?.statusStep)
    // setStudentStatus("ADMIN_APPROVED")
    // setStudentStatus("APPROVED")
    setStatusRA(
      data?.statusStep == "REQUEST"
        ? true
        : data?.statusStep == "APPROVED"
        ? true
        : false
    );
  }, [data]);

  useEffect(async () => {
    if (clickCheckbox == true) {
      try {
        updateStudent({
          variables: {
            where: { id: data?.id },
            data: { acceptTerms: true },
          },
        });
      } catch (err) {
        console.log("err", err);
      }
    }
    if (clickCheckbox == false) {
      try {
        await updateStudent({
          variables: {
            where: { id: data?.id },
            data: { acceptTerms: false },
          },
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  }, [clickCheckbox, data]);
  // const data = dataStudent?.data?.students?.data[0]

  const _clickCard = () => {
    history.push("/registrationstudentinfoEn", data);
  };
  const _clickPresentATemporaryStudentCard = () => {
    history.push("/presentatemporarystudentcard");
  };
  const _clickTuition_payment_channels = () => {
    history.push(`/tuitionpaymentchannels`, data);
  };
  const _clickQRcodePresentATemporaryStudentCard = () => {
    history.push("/onepay");
  };
  const _clickShowDetail = () => {
    history.push("/payment/successpayment", data);
    // history.push('/tuitionpaymentchannels', data)
  };

  const _clickCheckbox = () => {
    setClickCheckbox(false);
    setClickCheckbox(!clickCheckbox);
    setAcceptTerms(!clickCheckbox);
  };

  const onConvertGender = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "Mr";
        break;
      case "FEMALE":
        result = "Miss";
        break;
      case "MONK":
        result = "ຄູບາ";
        break;
      case "NOVICE":
        result = "ສຳມະເນນ";
        break;
    }
    return result;
  };

  if(loadStudentData) return (<Loading />)
  return (
    <div className="container container-sm container-md container-lg container-xl container-fluid">
      <div className="card card-margin-top">
        <div className="card-body">
          <h1 className="card-titlen text-resize5">Information</h1>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10 col-xl-10">
              <div className="card-card-dashboard">
                <div className="user-radius">
                  <FontAwesomeIcon
                    className="user-radius size-responsive"
                    icon={faUserCircle}
                    size="7x"
                    color={"#888888"}
                  />
                </div>
              </div>
              <div className="card-dashboard-body" style={{border: "1px solid #ccc" }}>
                <div className="col-xs-12 col-md-6 offset-md-3 text-card-student">
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-5">
                      <b>Name and Surname:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-5">
                      <span>
                        {onConvertGender(data?.gender)} {data?.firstNameE}{" "}
                        {data?.lastNameE}
                      </span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>Registration Number:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>{data?.studentNumber}</span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>Date of Birth:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>
                        {formatDate(data?.birthday)}
                      </span>
                    </div>
                  </div>
                  <div className="text-resize3 row">
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <b>Update:</b>
                    </div>
                    <div className="col-6 col-xs-6 col-md-6 mt-2">
                      <span>
                        {formatDate(data?.createdAt) ?? "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-md-6 offset-md-3 text-card-student text-resize3 mt-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: "#057CAE",
                    textDecoration: "underline",
                  }}
                  onClick={() => _clickCard()}
                >
                  {statusSteps === false ?
                  <span style={{ color: "#FF0000" }}>
                    Rejected{" "}
                    <span
                      onClick={() =>
                        history.push(`/registrationStudentEn`, {code: data?.code, studentType: data?.studentType})
                      }
                      style={{
                        color: "#057CAE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      (Edit)
                    </span>
                  </span>
                  : <b>Student detail</b>
                  }
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="w-100">
              <h3 className="text-resize5">Registration Steps:</h3>
              <Stepper orientation="vertical" >
                <Step active={statusSteps}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h5 className="text-bold colorblack text-resize3 p-0 m-0">
                          Step 1 | Form Registration
                        </h5>
                      </div>
                      <div className="col-md-1 status-webView" style={{height: 20}} />
                      <div className="col-md-3 status-webView">
                        <div className="text-resize3">
                          Status:
                          <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                            {" "}
                            Success
                          </span>
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        You have successfuly completed the Registration Form
                        <br/> Please go to the NUOL Academic Office for confirmation
                      </div>

                      <div className="text-resize3 mt-3 status-mobileView">
                        Status:
                        <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                          {" "}
                          Success
                        </span>
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus == "ADMIN_APPROVED" || studentStatus == "APPROVED" ? true : false}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h5 className="text-bold colorblack text-resize3 p-0 m-0">
                          Step 2 | Go to the Academic Office
                        </h5>
                      </div>
                      <div className="col-md-1 status-webView" style={{height: 20}} />
                      <div className="col-md-3 status-webView">
                        <div className="text-resize3">
                          Status:
                          { studentStatus == "ADMIN_APPROVED" || studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              Success
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              Pending
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        Use the documents in the envelop or temporary student card <br />
                        (For instance: Entrance exam card, photo, ID card and please bring the original documents)
                      </div>
                      <div className="text-resize3 mt-3 status-mobileView">
                        Status:
                        { studentStatus == "ADMIN_APPROVED" || studentStatus == "APPROVED" ?
                          <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                            {" "}
                            Success
                          </span>
                          :
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            {" "}
                            Pending
                          </span>
                        }
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                {/* <Step active={studentStatus == "FACULTY_APPROVED" || studentStatus == "APPROVED" ? true : false}> */}
                <Step active={studentStatus == "APPROVED" ? true : false}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h5 className="text-bold colorblack text-resize3 p-0 m-0">
                          Step 3 | Go to the Faculty
                        </h5>
                      </div>
                      <div className="col-md-1 status-webView" style={{height: 20}} />
                      <div className="col-md-3 status-webView">
                        <div className="text-resize3">
                          Status:
                          { studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              Success
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              Pending
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ​Documents to possess when approaching the faculty <br />
                        1. Student's temporary identify card and Registration form 3 <br />
                        2. Receipt(s) <br />
                        3. Transcript <br />
                        4. Letter of approval and accompanied documents <br />
                        5. All documents have to be in English
                      </div>
                      <div className="text-resize3 mt-3 status-mobileView">
                        Status:
                        { studentStatus == "APPROVED" ?
                          <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                            {" "}
                            Success
                          </span>
                          :
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            {" "}
                            Pending
                          </span>
                        }
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus == "APPROVED"}>
                  <StepLabel>
                    <div className="row">
                      <div className="col-md-8">
                        <h5 className="text-bold colorblack text-resize3 p-0 m-0">
                          Step 4 | Registration Success
                        </h5>
                      </div>
                      <div className="col-md-1 status-webView" style={{height: 20}} />
                      <div className="col-md-3 status-webView">
                        <div className="text-resize3">
                          Status:
                          { studentStatus == "APPROVED" ?
                            <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                              {" "}
                              Success
                            </span>
                            :
                            <span style={{ color: "orange", fontWeight: "bold" }}>
                              {" "}
                              Pending
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent active={studentStatus == "APPROVED"}>
                    <Typography>
                      <div className="text-resize3">
                        <b className="text-success">Registration Success</b>
                        <div className="text-resize3 mt-3">
                          UserId and Password for Student app
                        </div>
                        <div className="text-resize3">
                          UserId: "<strong>{data?.studentNumber}</strong>"
                        </div>
                        <div className="text-resize3">
                          Password: "<strong>{data?.note ? data?.note : data?.studentNumber}</strong>"
                        </div>
                        <br />
                        <div className="text-resize3">
                          You can download app on App store and Play store
                          Search this keyword " Smart Learning System "
                        </div>
                        <div className="text-resize3">
                          Or download using link:
                        </div>
                        <div className="text-resize3">
                          For <strong>Android:</strong>{" "}
                          <u>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.laodevhub.slms&fbclid=IwAR2MpjYjUBabDWPeyZ7Exh0I6Ct25knM38t-apnIL6NPs2hlTgybbqAr-lo"
                              target="bank"
                            >
                              Click here
                            </a>
                          </u>
                        </div>
                        <div className="text-resize3">
                          For <strong>iOS</strong>{" "}
                          <u>
                            <a
                              href="https://apps.apple.com/la/app/slms-system/id1587292699?fbclid=IwAR3iAVtimzmFzp34aTFhONCaYyNgAl7Lqj2F3agk_FozcrDhVC3BPwxLBLE"
                              target="bank"
                            >
                              Click here
                            </a>
                          </u>
                        </div>
                      </div>
                      <div className="text-resize3 mt-3 status-mobileView">
                        Status:
                        { studentStatus == "APPROVED" ?
                          <span style={{ color: "#057CAE", fontWeight: "bold" }}>
                            {" "}
                            Success
                          </span>
                          :
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            {" "}
                            Pending
                          </span>
                        }
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
