import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import useReactRouter from "use-react-router";
import { Col, Modal, Button } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from '../../common/Loading'
import {
  CREATE_STUDENT,
  UPDATE_STUDENT,
} from "../../apollo/registrationstudent";
import { onConvertGender, onConvertMaritualStatus, onConvertStudentInfo, onConvertEducationLevel, onConvertGenderEn, convertGender } from "../../common/super";
import { STUDENTS, TRIBES } from "../../apollo/students";

// CourseAddConfirm
function RegistrationConfirm({
  param,
  studentType,
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
}) {
  let NOW = new Date().getFullYear();
  let assessmentYearNow = NOW + " - " + (NOW + 1);
  const { history, location, match } = useReactRouter();
  const [dataQuey, setDataQuey] = useState();
  const [createStudent, { loading }] = useMutation(
    CREATE_STUDENT
  );
  const [loadeTribe,{ data: dataTribe }] = useLazyQuery(TRIBES);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [show, setShow] = useState(false);
  const [tribeName, setTribeName] = useState('');
  const [loadStudentData, { data: dataStudent }] = useLazyQuery(STUDENTS);
  useEffect(() => {
    loadStudentData({
      variables: {
        where: { code: param?.data?.code, assessmentYear: assessmentYearNow },
      },
    });
    loadeTribe()
  }, [param]);

  useEffect(() => {
    setDataQuey(dataStudent?.students?.data[0]);
    const index = _.findIndex(dataTribe?.tribes,{
      id: param?.data?.tribe
    })
    setTribeName(dataTribe?.tribes[index]?.name)
  }, [dataStudent, dataTribe]);
  
  // SEND_FORM
  const confirmRegistration = async () => {

    try {
      if (dataQuey?.statusStep === "SAVED") {
        delete param.data.facultyname;
        delete param.data.majorName;
        delete param.data.statusStepUpdate;
        updateStudent({
          variables: {
            where: { id: dataQuey?.id },
            data: param.data,
          },
        });
        _handleShowAddConfirmModalClose();
        setShow(true);
        setTimeout(() => {
          setShow(false);
          history.push(`/cardstudent/${param?.data?.code}`);
          window.location.reload();
        }, 1000);
      } else if (dataQuey?.statusStep === "REJECTED") {
        delete param.data.facultyname;
        delete param.data.majorName;
        delete param.data.statusStepUpdate;
        updateStudent({
          variables: {
            where: { id: dataQuey?.id },
            data: param.data,
          },
        });
        _handleShowAddConfirmModalClose();
        setShow(true);
        setTimeout(() => {
          setShow(false);
          history.push(`/cardstudent/${param?.data?.code}`);
          window.location.reload();
        }, 1000);
      } else {
        delete param.data.facultyname;
        delete param.data.majorName;
        const createNewStudent = await createStudent({ variables: param });
        const isEmpty = _.isEmpty(createNewStudent)
        if(createNewStudent && !isEmpty) {
          _handleShowAddConfirmModalClose();
          setShow(true);
          setTimeout(() => {
            setShow(false);
            history.push(`/cardstudent/${param?.data?.code}`);
            window.location.reload();
          }, 1000);
        }
      }
    } catch (err) {
      console.error("err:", err.message);
      if (err.message == "GraphQL error: EMAIL_IS_ALREADY_EXITS") {
        alert("Email ນີ້ມີຢູ່ແລ້ວ");
      }
      if (err.message == "GraphQL error: PHONE_NUMBER_IS_ALREADY_EXITS") {
        alert("ເບີໂທນີ້ ນີ້ມີຢູ່ແລ້ວ");
      }
    }
  };
  if(loading) return <Loading />
  return (
    <div>
      <Modal
        // style={{ marginTop: 50 }}
        show={showAddConfirmModal}
        onHide={_handleShowAddConfirmModalClose}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Col
            style={{
              marginTop: 20,
              fontSize: 30,
              fontWeight: "bold",
              color: "#057CAE",
            }}
          >
            ຢືນ​ຢັນ​ການສົ່ງ​ຂໍ້​ມູນ
          </Col>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
            </li>
            <li className="list-group-item">
            ລະຫັດສອບເສັງ:{" "}
              <span className="float-right">{param?.data?.examNumber}</span>
            </li>
            <li className="list-group-item">
              ຄະ​ນ​ະ:{" "}
              <span className="float-right">{param?.data?.facultyname}</span>
            </li>
            <li className="list-group-item">
              ສາ​ຂາ​ວິ​ຊາ:{" "}
              <span className="float-right">{param?.data?.majorName}</span>
            </li>
            <li className="list-group-item">
              ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ:{" "}
              <span className="float-right">
                {param &&
                  (param?.data?.studentInfo
                    ? onConvertStudentInfo(param?.data?.studentInfo)
                    : "-")}
              </span>
            </li>
            <li className="list-group-item">
              ລະ​ດັບ:{" "}
              <span className="float-right">
                {param &&
                  (param?.data?.educationLevel
                    ? onConvertEducationLevel(param?.data?.educationLevel)
                    : "-")}
              </span>
            </li>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item bg-active"
                style={{ color: "#fff", fontWeight: "bold" }}
              >
                <FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນ​ທົ່ວໄປ
              </li>

              <li className="list-group-item">
                ຊື່:{" "}
                <span className="float-right">{param?.data?.firstNameL}</span>
              </li>
            </ul>
            <li className="list-group-item">
              ນາມ​ສະ​ກຸນ:{" "}
              <span className="float-right">{param?.data?.lastNameL}</span>
            </li>
            <li className="list-group-item">
              ຊື່ (​ພາ​ສາ​ອັງ​ກິດ){" "}
              <span className="float-right">{onConvertGenderEn(param?.data?.gernder)} {param?.data?.firstNameE}</span>
            </li>
            <li className="list-group-item">
              ນາມ​ສະ​ກຸນ (​ພາ​ສາ​ອັງ​ກິດ){" "}
              <span className="float-right">{param?.data?.lastNameE}</span>
            </li>
            <li className="list-group-item">
              ວັນ​ເດືອນ​ປີ​ເກີດ:{" "}
              <span className="float-right">{param?.data?.birthday}</span>
            </li>
            <li className="list-group-item">
              ເພດ:{" "}
              <span className="float-right">
                {param &&
                  (param?.data?.gender ? convertGender(param?.data?.gender) : "-")}
              </span>
            </li>
            <li className="list-group-item">
              ສະ​ຖາ​ນະ:{" "}
              <span className="float-right">
                {param &&
                  (param?.data?.maritualStatus
                    ? onConvertMaritualStatus(param?.data?.maritualStatus)
                    : "-")}
              </span>
            </li>
            <li className="list-group-item">
              ສັນ​ຊາດ:{" "}
              <span className="float-right">{param?.data?.nationality}</span>
            </li>
            <li className="list-group-item">
              ຊົນ​ເຜົ່າ:{" "}
              <span className="float-right">{tribeName}</span>
            </li>
            <li className="list-group-item">
              ສາ​ສະ​ໜາ:{" "}
              <span className="float-right">{param?.data?.religion}</span>
            </li>
            <li className="list-group-item">
              ເບີໂທເຮືອນ:{" "}
              <span className="float-right">{param?.data?.phoneHome}</span>
            </li>
            <li className="list-group-item">
              ເບີໂທລະສັບມືຖື:{" "}
              <span className="float-right">{param?.data?.phoneMobile}</span>
            </li>
            <li className="list-group-item">
              ອີເມວ: <span className="float-right">{param?.data?.email}</span>
            </li>
          </ul>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ທີ່ຢູ່ປະຈຸບັນ
            </li>
            <li className="list-group-item">
              ແຂວງ:{" "}
              <span className="float-right">
                {param?.data?.address?.province}
              </span>
            </li>
            <li className="list-group-item">
              ເມືອງ:{" "}
              <span className="float-right">
                {param?.data?.address?.district}
              </span>
            </li>
            <li className="list-group-item">
              ບ້ານ:{" "}
              <span className="float-right">
                {param?.data?.address?.village}
              </span>
            </li>
          </ul>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ສະຖານທີ່ເກີດ
            </li>
            <li className="list-group-item">
              ແຂວງ:{" "}
              <span className="float-right">
                {param?.data?.birthAddress?.province}
              </span>
            </li>
            <li className="list-group-item">
              ເມືອງ:{" "}
              <span className="float-right">
                {param?.data?.birthAddress?.district}
              </span>
            </li>
            <li className="list-group-item">
              ບ້ານ:{" "}
              <span className="float-right">
                {param?.data?.birthAddress?.village}
              </span>
            </li>
          </ul>
          {studentType !== "IN_PLAN" ? (
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item bg-active"
                style={{ color: "#fff", fontWeight: "bold" }}
              >
                <FontAwesomeIcon icon={faCaretDown} />{" "}
                ສຳລັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
              </li>
              <li className="list-group-item">
                ຈົບຈາກແຂວງ:{" "}
                <span className="float-right">
                  {param?.data?.graduateFromProvince}
                </span>
              </li>
              <li className="list-group-item">
                ຈົບຈາກໂຮງຮຽນ:{" "}
                <span className="float-right">
                  {param?.data?.graduateFromSchool}
                </span>
              </li>
              <li className="list-group-item">
                ສົກສືກສາ:{" "}
                <span className="float-right">{param?.data?.semesterYear}</span>
              </li>
            </ul>
          ) : (
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item bg-active"
                style={{ color: "#fff", fontWeight: "bold" }}
              >
                <FontAwesomeIcon icon={faCaretDown} /> ສໍາຫຼັບນັກສືກສາ/ພະນັກງານ
                ທືນແບ່ງປັນ
              </li>
              <li className="list-group-item">
                ຈົບ​ຈາກແຂວງ:{" "}
                <span className="float-right">
                  {param?.data?.graduateFromProvince}
                </span>
              </li>
              <li className="list-group-item">
                ຈົບຈາກໂຮງຮຽນ:{" "}
                <span className="float-right">
                  {param?.data?.graduateFromSchool}
                </span>
              </li>
              <li className="list-group-item">
                ສົກສືກສາ:{" "}
                <span className="float-right">{param?.data?.semesterYear}</span>
              </li>
            </ul>
          )}
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
            </li>
            <li className="list-group-item">
              ຊື່ ແລະ ນາມສະກຸນພໍ່:{" "}
              <span className="float-right">
                {param?.data?.fatherNameAndSurname}
              </span>
            </li>
            <li className="list-group-item">
              ຊື່ ແລະ ນາມສະກຸນແມ່:{" "}
              <span className="float-right">
                {param?.data?.motherNameAndSurname}
              </span>
            </li>
            <li className="list-group-item">
              ເບີໂທລະສັບພໍ່/ແມ່:{" "}
              <span className="float-right">
                {param?.data?.parentTelephone}
              </span>
            </li>
            <li className="list-group-item">
              ແຂວງ:{" "}
              <span className="float-right">
                {param?.data?.parentAddress?.province}
              </span>
            </li>
            <li className="list-group-item">
              ເມືອງ:{" "}
              <span className="float-right">
                {param?.data?.parentAddress?.district}
              </span>
            </li>
            <li className="list-group-item">
              ບ້ານ:{" "}
              <span className="float-right">
                {param?.data?.parentAddress?.village}
              </span>
            </li>
          </ul>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ​ກໍ​ລະ​ນີ​ສຸກ​ເສີນ​ຕິດ​ຕໍ່
            </li>
            <li className="list-group-item">
              ພົວ​ພັນ​ທ່ານ:{" "}
              <span className="float-right">{param?.data?.contactName}</span>
            </li>
            <li className="list-group-item">
              ​ເບີ​ໂທ​ຕິດ​ຕໍ່:{" "}
              <span className="float-right">{param?.data?.emergencyPhone}</span>
            </li>
            <li className="list-group-item">
              ອ​າ​ໄສ​ຢູ່​ຮ່ວມ​ກັນ:{" "}
              <span className="float-right">
                {param?.data?.stayTogether == true ? "ຢູ່" : "ບໍ່ຢູ່"}
              </span>
            </li>
          </ul>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ອັບໂຫຼດເອກະສານຄັດຕິດ
            </li>
            <li className="list-group-item">
              ອັບໂຫຼດໄຟລຮູບ ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ:{" "}
              <span className="float-right">
                {param?.data?.idCardOrFamilyBookUrl?.url}
              </span>
            </li>
            <li className="list-group-item">
              ອັບໂຫຼດໄຟລຮູບ ໃບປະກາດ{" "}
              <span className="float-right">
                {param?.data?.certificateUrl?.url}
              </span>
            </li>
            <li className="list-group-item">
              ອັບໂຫຼດໄຟລຮູບ ໃບຄະແນນ{" "}
              <span className="float-right">
                {param?.data?.scorecardUrl?.url}
              </span>
            </li>
            <li className="list-group-item">
              ອັບໂຫຼດໄຟລຮູບ ບັດເຂົ້າຫ້ອງເສັງ:{" "}
              <span className="float-right">
                {param?.data?.testCardUrl?.url}
              </span>
            </li>
            {studentType === "IN_PLAN" ?
            <li className="list-group-item">
              ອັບໂຫຼດໄຟລຮູບ ໃບນຳສົ່ງ:{" "}
              <span className="float-right">
                {param?.data?.deliveryCardUrl?.url}
              </span>
            </li>
            : <></>
            }
          </ul>
          <hr />
          <div className="button-confirm">
            <center>
              <Button
                type="submit"
                className="buttonoutline "
                onClick={() => _handleShowAddConfirmModalClose()}
              >
                ຍົກເລີກ
              </Button>
              <Button
                type="submit"
                className="btn btn-default bg-active button-apply-confirm marign-button-apply-right"
                onClick={() => confirmRegistration()}
              >
                ຢືນຢັນ
              </Button>
            </center>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size72 text-bold"
              style={{ color: "#00A1DE" }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationConfirm;
