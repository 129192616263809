import React, { Component } from "react";
import Routes from "./routes/Routes";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";

import { USER_KEY } from "./consts";

library.add(fab, fas);
dom.watch();

const client = new ApolloClient({
  uri: process.env.REACT_APP_PRE_GRAPHQL_URL,

  request: (operation) => {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      const token = JSON.parse(user)["accessToken"];
      operation.setContext({
        headers: {
          Authentication: token ? "Nuol " + token : "",
        },
      });
      return;
    }
  },

});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    );
  }
}

export default App;

