import { gql } from "apollo-boost";

export const LOGIN = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      id
      code
      department{
        id
        name
      }
      faculty{
        id
        name
      }
      major{
        id
        name
      }
      firstNameL
      lastNameL
      firstNameE
      lastNameE
      studentType
      gender
      contactName
      emergencyPhone
      birthday
      maritualStatus
      nationality
      religion
      tribe{
        id
        name
      }
      phoneMobile
      address{
        province
        district
        village
      }
      birthAddress{
        province
        district
        village
      }
      graduateFromProvince
      graduateFromSchool
      semesterYear
    }
  }
`;